import React from 'react';

import { LinearProgress } from '@mui/material';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';

const AddFeeTable = (props) => {
    const { feeRules, setSelectedFee } = props;

    const getRow = (params) => {
        setSelectedFee(params.row);
    }

    const columns = [
        {
            field: 'caseType',
            headerName: 'Charge Type',
            width: 250
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 500
        },
        {
            field: 'amount',
            headerName: 'Assess Amount',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => {
                if (value === undefined || value === null) return "";
                return `$${value.toFixed(2).toLocaleString()}`;
            },
        },
    ];

    return (
        <DataGridPremium
            rows={feeRules}
            columns={columns}
            disableMultipleRowSelection
            onRowClick={getRow}
            localeText={{ noRowsLabel: 'No fees avaliable for this case' }}
            density="compact"
            pageSizeOptions={[25, 50, 100]}
            initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress
            }}
            disableColumnFilter
            disableColumnSelector // Hide the columns button
            disableExporting // Hide the export button
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                    "marginTop": "1em",
                    "marginBottom": "1em"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
                maxHeight: '55vh'
            }}
        />
    )
}

export default AddFeeTable;