import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

import { CaseContext } from '../../../../context/CaseContext';
import { apiRoute } from '../../../../App';

const FilesTable = (props) => {
    const { columns } = props;

    const { files, setFiles } = useContext(CaseContext);

    const { caseNumber } = useParams();

    const [loading, setLoading] = useState(false);

    // Fixes DataGrid RowId error
    useEffect(() => {
        if (files.length < 1) {
            setLoading(true);
            axios.get(`${apiRoute}/api/FilesTbl/allByCaseId/${caseNumber}`)
                .then((res) => {
                    setFiles(res.data.map((data) => ({
                        ...data,
                        id: data.pkFilesId
                    })));
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [files])

    return (
        <DataGridPremium
            rows={files}
            columns={columns}
            disableMultipleRowSelection
            autoHeight={false}
            loading={loading}
            slots={{
                loadingOverlay: LinearProgress
            }}
            pageSizeOptions={[25, 50, 100]}
            initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
            }}
            localeText={{
                noRowsLabel:
                    'No files. Select files to upload above'
            }}
            sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                    "marginTop": "1em",
                    "marginBottom": "1em"
                },
            }}
        />
    )
};

export default FilesTable;