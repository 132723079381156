import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const DisburseDatePicker = (props) => {
    const {
        disburseDatesList,
        loadingDates,
        setDisburseDate,
        setPreviousDate,
        setDisburseId
    } = props

    //const [disburseDate, setDisburseDate] = useState();
    //const [loadingDates, setLoadingDates] = useState(false);
    //const [disburseDatesList, setDisburseDatesList] = useState([]);
    //const [previousDate, setPreviousDate] = useState();



    //const getDisburseDates = () => {
    //    setLoadingDates(true);
    //    axios.get(`${apiRoute}/api/DisburseReportViews`)
    //        .then((res) => {
    //            const dateRows = res.data.map((row, i) => {
    //                return {
    //                    id: `${row.disburseDate}-${i}`,
    //                    Date: formattedDate(row.disburseDate),
    //                    Time: formatTime(row.disburseDate),
    //                    EnteredBy: row.enteredBy,
    //                    Value: row.disburseDate
    //                }
    //            })
    //            setDisburseDatesList(dateRows);
    //        })
    //        .catch((err) => {
    //            console.error("error getting disburse dates", err);
    //        })
    //        .finally(() => setLoadingDates(false))
    //};

    //useEffect(() => {
    //    getDisburseDates();
    //}, [])

    return (
        <DataGridPremium
            columns={[
                {
                    field: "Date",
                    headerName: "Disburse Date",
                    flex: 1
                },
                {
                    field: "Time",
                    headerName: "Disburse Time",
                    flex: 1

                },
                {
                    field: "EnteredBy",
                    headerName: "Disbursed By",
                    flex: 1
                }
            ]}
            rows={disburseDatesList}
            loading={loadingDates}
            autoHeight={false}
            onRowClick={(currentRow) => {
                console.log('currentRow', currentRow);
                setDisburseDate(currentRow.row.FullDate);
                setDisburseId(currentRow.row.Value);
                const currentIndex = disburseDatesList.findIndex((row) => row.id === currentRow.id);
                setPreviousDate(disburseDatesList[currentIndex + 1]?.Value)
            }}
            localeText={{ noRowsLabel: "No Disbursement Dates Found" }}
            initialState={{
                pagination: { paginationModel: { pageSize: 25 } }
            }}
            sx={{
                height: '50vh',
                width: '30vw',
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                    "marginTop": "1em",
                    "marginBottom": "1em"
                },

                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                },
            }}
        />
    )
}

export default DisburseDatePicker;