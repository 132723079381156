import React, { useState } from 'react';
import axios from 'axios';

import { Box, Button, InputLabel, Paper, TextField, Typography, LinearProgress, InputAdornment } from '@mui/material';
import { MdEditOff } from "react-icons/md";
import { ErrorOutline } from '@mui/icons-material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../App';
import { commaMoneyFormat } from '../../../../Utils';

const CloseOutCountModal = (props) => {
    const { selectedRow, handleCloseOutCountModal, handleBatchClose, ts, loading, setLoading } = props;

    const [payload, setPayload] = useState({});

    const [openSupervisorApprovalForm, setOpenSupervisorApprovalForm] = useState(false);

    const handleOpenSupervisorApprovalForm = () => setOpenSupervisorApprovalForm(!openSupervisorApprovalForm);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errors, setErrors] = useState({});

    // STATE FOR THE ACTUAL VALUES OF THE FIELDS
    const [valueState, setValueState] = useState({
        bills100: 0,
        bills100Value: 0,
        bills50: 0,
        bills50Value: 0,
        bills20: 0,
        bills20Value: 0,
        bills10: 0,
        bills10Value: 0,
        bills5: 0,
        bills5Value: 0,
        bills2: 0,
        bills2Value: 0,
        bills1: 0,
        bills1Value: 0,
        coins1: 0,
        coins1Value: 0,
        halfDollars: 0,
        halfDollarsValue: 0,
        quarters: 0,
        quartersValue: 0,
        dimes: 0,
        dimesValue: 0,
        nickles: 0,
        nicklesValue: 0,
        pennies: 0,
        penniesValue: 0,
        cashCount: 0,
        changeGiven: 0,
        cashDeposit: selectedRow.cash,
        variance: 0,
        nonCashTotal: +selectedRow.moneyOrder + +selectedRow.cashiersCheck,
        nonCashCollected: 0,
        nonCashVariance: 0,
        totalVariance: 0,
        checkTotal: 0,
        moneyOrderTotal: 0,
        checksCollected: 0,
        moneyOrdersCollected: 0,
        comments: '',
    });
    // STATE FOR DISPLAYING THE VALUES OF THE FIELDS USING THE MONEY FORMATTER
    const [displayState, setDisplayState] = useState({
        changeGiven: '$0.00',
        bills100Value: '$0.00',
        bills50Value: '$0.00',
        bills20Value: '$0.00',
        bills10Value: '$0.00',
        bills5Value: '$0.00',
        bills2Value: '$0.00',
        bills1Value: '$0.00',
        coins1Value: '$0.00',
        halfDollarsValue: '$0.00',
        quartersValue: '$0.00',
        dimesValue: '$0.00',
        nicklesValue: '$0.00',
        penniesValue: '$0.00',
        cashCount: '$0.00',
        cashCollected: '$0.00',
        variance: '$0.00',
        checksTotal: '$0.00',
        moneyOrdersTotal: '$0.00',
        checksCollected: '$0.00',
        moneyOrdersCollected: '$0.00',
        nonCashTotal: commaMoneyFormat(selectedRow.moneyOrder + selectedRow.cashiersCheck),
        nonCashCollected: '$0.00',
        nonCashVariance: '$0.00',
    });

    const [nonCashVariance, setNonCashVariance] = useState(+valueState.nonCashTotal - +valueState.nonCashCollected);

    // Define a function to calculate Cash_Count
    const calculateCashCount = (state) => {
        const cashCount =
            state.bills100Value +
            state.bills50Value +
            state.bills20Value +
            state.bills10Value +
            state.bills5Value +
            state.bills2Value +
            state.bills1Value +
            state.coins1Value +
            state.halfDollarsValue +
            state.quartersValue +
            state.dimesValue +
            state.nicklesValue +
            state.penniesValue;
        return cashCount;
    };

    const handleSubmitCloseOutCount = () => {
        const payload = {
            FkBatchId: selectedRow.pkBatchId,
            Bills_100: valueState.bills100,
            Bills_50: valueState.bills50,
            Bills_20: valueState.bills20,
            Bills_10: valueState.bills10,
            Bills_5: valueState.bills5,
            Bills_2: valueState.bills2,
            Bills_1: valueState.bills1,
            Coins_1: valueState.coins1,
            Half_Dollars: valueState.halfDollars,
            Quarters: valueState.quarters,
            Dimes: valueState.dimes,
            Nickles: valueState.nickles,
            Pennies: valueState.pennies,
            Cash_Count: valueState.cashCount,
            Change_Given: valueState.changeGiven,
            Cash_Deposit: valueState.cashDeposit,
            Variance: valueState.variance,
            Non_Cash_Total: valueState.nonCashTotal,
            Non_Cash_Variance: valueState.nonCashVariance,
            Comments: valueState.Comments,
            Total_Variance: valueState.totalVariance,
            Check_Total: valueState.checkTotal,
            Money_Order_Total: valueState.moneyOrderTotal,
        };

        if (payload.totalVariance === 0) {
            setLoading(true);
            axios.post(`${apiRoute}/api/BatchCloseoutTbls`, payload)
                .then(() => {
                    toast.success('Close Out Drawer Count added successfully!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleBatchClose();
                    handleCloseOutCountModal();
                })
                .catch((err) => {
                    console.log('error adding close out drawer count', err);
                    toast.error(`Error ${err.response.status} while adding close out drawer count for Batch ${selectedRow.batchNumber}.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                })
                .finally(() => setLoading(false));
        } else {
            handleOpenSupervisorApprovalForm();
            setPayload(payload);
        }
    };

    const handleSubmitSupervisorApprovalModal = async () => {
        setLoading(true);
        axios.post(`${apiRoute}/api/users/checkUser`, { email: email, password: password })
            .then((res) => {
                if (res.data) {
                    handleOpenSupervisorApprovalForm();
                    axios.post(`${apiRoute}/api/BatchCloseoutTbls`, payload)
                        .then(() => {
                            toast.success(`Batch ${selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber} closed and Close Out Drawer Count added successfully!`, {
                                position: 'top-right',
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                            handleBatchClose(false);
                            handleCloseOutCountModal();
                        })
                        .catch((err) => {
                            console.log('error adding close out drawer count', err);
                            toast.error(`Error ${err.response.status} while adding close out drawer count for ${selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber}.`, {
                                position: 'top-right',
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'colored',
                            });
                        })
                } else {
                    return toast.error(`Invalid supervisor login.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                }
            })
            .catch((err) => {
                console.log('error confirming supervisor', err);
                if (err.response.data === "Invalid email or password") {
                    toast.error(`${err.response.data}`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                } else {
                    toast.error(`Error ${err.response.status} while confirming supervisor.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    return (<>
        {!openSupervisorApprovalForm && (
            <Paper pb={1} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                    <h2>
                        <strong>Batch {selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber} Close Out Drawer Count</strong>
                    </h2>
                </Paper>
                <Box display="flex" p={1} sx={{ maxHeight: '75vh', overflow: 'auto' }}>
                    <Box width={1 / 2} flexWrap="wrap" display="flex" alignItems="center">
                        <Box width={[1, 1, 1 / 4]} mt={1}>
                            <InputLabel sx={ts} htmlFor="Change_Given">
                                Starting Till Amount
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={1} pr={2} />
                        <Box width={[1, 1, 1 / 3]} mt={1} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="changeGiven"
                                name="changeGiven"
                                value={displayState.changeGiven}
                                onClick={(e) => {
                                    setDisplayState({ ...displayState, changeGiven: '' });
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const sanitizedValue = value.replace(/[^0-9.]/g, '');
                                    setDisplayState({
                                        ...displayState,
                                        changeGiven: sanitizedValue,
                                        variance: commaMoneyFormat(valueState.cashCount - (parseFloat(sanitizedValue) + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(valueState.cashCount - parseFloat(sanitizedValue) - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                    setValueState({
                                        ...valueState,
                                        changeGiven: parseFloat(sanitizedValue),
                                        variance: valueState.cashCount - (parseFloat(sanitizedValue) + valueState.cashDeposit),
                                        totalVariance: valueState.cashCount - parseFloat(sanitizedValue) - valueState.cashDeposit + valueState.nonCashVariance
                                    })

                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        setErrors({ ...errors, changeGiven: 'Required!' })
                                        setDisplayState({ ...displayState, changeGiven: '$0.00' })
                                        setValueState({ ...valueState, changeGiven: 0 })
                                    } else {
                                        setErrors({ ...errors, changeGiven: null })
                                        setDisplayState({ ...displayState, changeGiven: commaMoneyFormat(e.target.value) })
                                    }
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' } }}
                                size="small"
                                helperText={errors.changeGiven ? errors.changeGiven : ''}
                                error={errors.changeGiven}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={2}></Box>
                        <Box width={[1, 1, 1 / 3]} textAlign="center" mt={2} pr={2}>
                            <h5>Count</h5>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} textAlign="center" mt={2} pr={2}>
                            <h5>Value</h5>
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={1}>
                            <InputLabel sx={ts} htmlFor="bills100">
                                $100 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={1} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills100"
                                name="bills100"
                                type='number'
                                value={valueState.bills100}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills100Value: 100 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills100: +e.target.value,
                                        bills100Value: 100 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills100Value: commaMoneyFormat(100 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={1} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills100Value"
                                name="bills100Value"
                                value={displayState.bills100Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="bills50">
                                $50 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills50"
                                name="bills50"
                                value={valueState.bills50}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills50Value: 50 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills50: +e.target.value,
                                        bills50Value: 50 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills50Value: commaMoneyFormat(50 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills50Value"
                                name="bills50Value"
                                value={displayState.bills50Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="bills20">
                                $20 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills20"
                                name="bills20"
                                value={valueState.bills20}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills20Value: 20 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills20: +e.target.value,
                                        bills20Value: 20 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills20Value: commaMoneyFormat(20 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills20Value"
                                name="bills20Value"
                                value={displayState.bills20Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="bills10">
                                $10 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills10"
                                name="bills10"
                                value={valueState.bills10}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills10Value: 10 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills10: +e.target.value,
                                        bills10Value: 10 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills10Value: commaMoneyFormat(10 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills10Value"
                                name="bills10Value"
                                value={displayState.bills10Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="bills5">
                                $5 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills5"
                                name="bills5"
                                value={valueState.bills5}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills5Value: 5 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills5: +e.target.value,
                                        bills5Value: 5 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills5Value: commaMoneyFormat(5 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills5Value"
                                name="bills5Value"
                                value={displayState.bills5Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="bills2">
                                $2 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills2"
                                name="bills2"
                                value={valueState.bills2}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills2Value: 2 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills2: +e.target.value,
                                        bills2Value: 2 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills2Value: commaMoneyFormat(2 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills2Value"
                                name="bills2Value"
                                value={displayState.bills2Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="ills1">
                                $1 Bills
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="bills1"
                                name="bills1"
                                value={valueState.bills1}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        bills1Value: 1 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        bills1: +e.target.value,
                                        bills1Value: 1 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        bills1Value: commaMoneyFormat(1 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="bills1Value"
                                name="bills1Value"
                                value={displayState.bills1Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="coins1">
                                $1 Coins
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="coins1"
                                name="coins1"
                                value={valueState.coins1}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        coins1Value: 1 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        coins1: +e.target.value,
                                        coins1Value: 1 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        coins1Value: commaMoneyFormat(1 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="coins1Value"
                                name="coins1Value"
                                value={displayState.coins1Value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="halfDollars">
                                Half Dollars
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="halfDollars"
                                name="halfDollars"
                                value={valueState.halfDollars}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        halfDollarsValue: 0.5 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        halfDollars: +e.target.value,
                                        halfDollarsValue: 0.5 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        halfDollarsValue: commaMoneyFormat(0.5 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="halfDollarsValue"
                                name="halfDollarsValue"
                                value={displayState.halfDollarsValue}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="quarters">
                                Quarters
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="quarters"
                                name="quarters"
                                value={valueState.quarters}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        quartersValue: 0.25 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        quarters: +e.target.value,
                                        quartersValue: 0.25 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        quartersValue: commaMoneyFormat(0.25 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="quartersValue"
                                name="quartersValue"
                                value={displayState.quartersValue}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="dimes">
                                Dimes
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="dimes"
                                name="dimes"
                                value={valueState.dimes}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        dimesValue: 0.1 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        dimes: +e.target.value,
                                        dimesValue: 0.1 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        dimesValue: commaMoneyFormat(0.1 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="dimesValue"
                                name="dimesValue"
                                value={displayState.dimesValue}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="nickles">
                                Nickles
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="nickles"
                                name="nickles"
                                value={valueState.nickles}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        nicklesValue: 0.05 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        nickles: +e.target.value,
                                        nicklesValue: 0.05 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        nicklesValue: commaMoneyFormat(0.05 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="nicklesValue"
                                name="nicklesValue"
                                value={displayState.nicklesValue}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="pennies">
                                Pennies
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                type="number"
                                id="pennies"
                                name="pennies"
                                value={valueState.pennies}
                                onChange={(e) => {
                                    const newCashCount = calculateCashCount({
                                        ...valueState,
                                        penniesValue: 0.01 * +e.target.value,
                                    });
                                    setValueState({
                                        ...valueState,
                                        pennies: +e.target.value,
                                        penniesValue: 0.01 * +e.target.value,
                                        cashCount: newCashCount,
                                        variance: newCashCount - (valueState.changeGiven + valueState.cashDeposit),
                                        totalVariance: newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance
                                    });
                                    setDisplayState({
                                        ...displayState,
                                        penniesValue: commaMoneyFormat(0.01 * +e.target.value),
                                        cashCount: commaMoneyFormat(newCashCount),
                                        variance: commaMoneyFormat(newCashCount - (valueState.changeGiven + valueState.cashDeposit)),
                                        totalVariance: commaMoneyFormat(newCashCount - valueState.changeGiven - valueState.cashDeposit + valueState.nonCashVariance)
                                    });
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                inputProps={{ min: 0, maxLength: 4 }}
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="penniesValue"
                                name="penniesValue"
                                value={displayState.penniesValue}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="cashCount">
                                Cash Count
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2} />
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="cashCount"
                                name="cashCount"
                                value={displayState.cashCount}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="cashDeposit">
                                Cash Collected
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2} />
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="cashDeposit"
                                name="cashDeposit"
                                value={commaMoneyFormat(selectedRow.cash)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    style: { textAlign: 'right' },
                                    readOnly: true
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 4]} mt={0.5}>
                            <InputLabel sx={ts} htmlFor="variance">
                                Variance
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={2} />
                        <Box width={[1, 1, 1 / 3]} mt={0.5} pr={1}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="variance"
                                name="variance"
                                value={displayState.variance}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true, style: { color: parseFloat(valueState.variance) > 0 ? "#c23f36" : "", textAlign: 'right', fontWeight: parseFloat(valueState.variance) > 0 ? "bold" : "" } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box width={1 / 2} flexWrap="wrap" display="flex" alignItems="center">
                        <Box width={1} mt={0.5}>
                            <h6 style={{ color: '#c23f36' }}>
                                Please note that you will need a supervisor's permission to close out anything
                                other than a $0.00 balance.
                            </h6>
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="checksTotal">
                                Checks Total
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="checksTotal"
                                name="checksTotal"
                                value={commaMoneyFormat(selectedRow.cashiersCheck)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' }, readOnly: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="moneyOrdersTotal">
                                Money Orders Total
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="moneyOrdersTotal"
                                name="moneyOrderTotal"
                                value={commaMoneyFormat(selectedRow.moneyOrder)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' }, readOnly: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="nonCashTotal">
                                Non Cash Total
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="nonCashTotal"
                                name="nonCashTotal"
                                value={displayState.nonCashTotal}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' }, readOnly: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="checksCollected">
                                Checks Collected
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="checksCollected"
                                name="checksCollected"
                                value={displayState.checksCollected}
                                onClick={(e) => {
                                    setDisplayState({ ...displayState, checksCollected: '' });
                                }}
                                onChange={(e) => {
                                    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
                                    setDisplayState({
                                        ...displayState,
                                        checksCollected: sanitizedValue,
                                        nonCashCollected: commaMoneyFormat(sanitizedValue + valueState.moneyOrdersCollected)
                                    });
                                    setValueState({
                                        ...valueState,
                                        checksCollected: +sanitizedValue,
                                        nonCashCollected: +sanitizedValue + valueState.moneyOrdersCollected
                                    });
                                    setNonCashVariance(valueState.nonCashTotal - (+sanitizedValue + valueState.moneyOrdersCollected))
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        setDisplayState({ ...displayState, checksCollected: '$0.00' });
                                        setValueState({ ...valueState, checksCollected: 0 });
                                    } else {
                                        setDisplayState({ ...displayState, checksCollected: commaMoneyFormat(e.target.value) });
                                    }
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' } }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="moneyOrdersCollected">
                                Money Orders Collected
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="moneyOrdersCollected"
                                name="moneyOrdersCollected"
                                value={displayState.moneyOrdersCollected}
                                onClick={(e) => {
                                    setDisplayState({ ...displayState, moneyOrdersCollected: '' });
                                }}
                                onChange={(e) => {
                                    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
                                    setDisplayState({
                                        ...displayState,
                                        moneyOrdersCollected: sanitizedValue,
                                        nonCashCollected: commaMoneyFormat(sanitizedValue + valueState.checksCollected)
                                    });
                                    setValueState({
                                        ...valueState,
                                        moneyOrdersCollected: +sanitizedValue,
                                        nonCashCollected: +sanitizedValue + valueState.checksCollected
                                    });
                                    setNonCashVariance(valueState.nonCashTotal - (+sanitizedValue + valueState.checksCollected))
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        setDisplayState({ ...displayState, moneyOrdersCollected: '$0.00' });
                                        setValueState({ ...valueState, moneyOrdersCollected: 0 });
                                    } else {
                                        setDisplayState({ ...displayState, moneyOrdersCollected: commaMoneyFormat(e.target.value) });
                                    }
                                }}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' } }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="nonCashCollected">
                                Non Cash Collected
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={1} />
                        <Box width={[1, 1, 1 / 3]} mb={0} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="nonCashTotal"
                                name="nonCashTotal"
                                value={commaMoneyFormat(valueState.moneyOrdersCollected + valueState.checksCollected)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' }, readOnly: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 3]}>
                            <InputLabel sx={ts} htmlFor="nonCashVariance">
                                Non Cash Variance
                            </InputLabel>
                        </Box>
                        <Box width={[1, 1, 1 / 3]} pr={1} />
                        <Box width={[1, 1, 1 / 3]} pr={2}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="nonCashVariance"
                                name="nonCashVariance"
                                value={commaMoneyFormat(nonCashVariance)}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'right' }, readOnly: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }}
                                size="small"
                            />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                            <InputLabel sx={ts} htmlFor="comments">
                                Notes/Comments
                            </InputLabel>
                        </Box>
                        <Box width={1} pr={2}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={5}
                                sx={ts}
                                id="comments"
                                name="comments"
                                value={valueState.Comments}
                                onChange={(e) => {
                                    setValueState({ ...valueState, Comments: e.target.value });
                                }}
                                variant="outlined"
                                inputProps={{ maxLength: 255 }}
                                size="small"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="right" gap={2} mt={1} pr={3} pb={1}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        onClick={handleSubmitCloseOutCount}
                    >
                        Save Deposit
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        onClick={handleCloseOutCountModal}
                    >
                        Cancel
                    </Button>
                </Box>
                {
                    loading && <LinearProgress />
                }
            </Paper>
        )}
        {openSupervisorApprovalForm && (
            <Paper sx={{ width: '65%', m: '0 auto' }} elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                    <h1 style={{ fontWeight: 'bold' }}>Supervisor Confirmation</h1>
                </Paper>
                <Box width={1} textAlign="center" fontSize="50px">
                    <ErrorOutline fontSize="inherit" />
                    <Typography mt={2} mb={4} mx={8} fontWeight={'bold'}>
                        You have requested an action that requires the confirmation of a supervisor.
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', width: '75%', m: '0 auto', mb: '3vh' }}>
                    <TextField
                        fullWidth
                        sx={ts}
                        id="email"
                        label="Supervisor Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                setErrors({ ...errors, email: 'Required!' })
                            } else {
                                setErrors({ ...errors, email: null })
                            }
                        }}
                        helperText={errors.email ? errors.email : ''}
                        error={errors.email}
                    />
                </Box>
                <Box sx={{ textAlign: 'center', width: '75%', m: '0 auto' }}>
                    <TextField
                        fullWidth
                        sx={ts}
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                setErrors({ ...errors, password: 'Required!' })
                            } else {
                                setErrors({ ...errors, password: null })
                            }
                        }}
                        helperText={errors.password ? errors.password : ''}
                        error={errors.password}
                    />
                </Box>
                <hr />
                <Box display="flex" justifyContent="right" gap={2} p={1}>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleSubmitSupervisorApprovalModal}>Confirm</Button>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleOpenSupervisorApprovalForm}>Cancel</Button>
                </Box>
                {
                    loading && <LinearProgress />
                }
            </Paper>
        )}
    </>);
};

export default CloseOutCountModal;