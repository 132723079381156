import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom'

import {
    Box, Grid, Button, MenuItem, TextField, FormControlLabel, Radio, RadioGroup, Paper, Tooltip, InputAdornment,
    List, ListItemText, ListItemButton, ListItemIcon, Checkbox, Card, CardHeader, Divider
} from '@mui/material';
import { MdEditOff } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from 'react-toastify';

import RemitterForm from './RemitterForm';

import { CaseContext } from '../../../../context/CaseContext';
import { UserContext } from '../../../../context/UserContext';
import { SystemContext } from '../../../../context/SystemContext';
import { BatchContext } from '../../../../context/BatchMgmtContext';
import { apiRoute } from '../../../../App.js';
import { commaMoneyFormat, formatMoney } from '../../../../Utils';

const PaymentForm = (props) => {
    const {
        handleClose,
        openBatches,
        violation,
        updateCaseBalance,
        modalType,
        ts,
        displayBalance,
        setDisplayBalance,
        violationBalance,
        loading,
        setLoading,
        remitterState,
        remitterDispatch,
        handleOpen,
        setReceiptNumber,
        setNewCaseBalance,
        newCaseBalance,
        citationState,
        setMasterReceipt,
        grand,
        setGrand,
        change,
        setChange,
        totalPayment,
        setTotalPayment,
        overridedPartialPayment,
        violationRows,
        setViolationRows
    } = props;

    const {
        violationId,
        setCaseHistoryRows,
        balance
    } = useContext(CaseContext);

    const { shiftDate, entityId } = useContext(UserContext);

    const { paymentOptions } = useContext(SystemContext);

    const { paymentBatchNumber, setPaymentBatchNumber } = useContext(BatchContext);

    const paymentRef = violation.currentStatute;

    const calcCredit = (daysInLieu, timeServed, violationId) => {
        let creditPerDay = perDayAmount(daysInLieu, violationId);
        let totalCredit = creditPerDay * timeServed;
        return isNaN(totalCredit) ? 0.00 : totalCredit;
    };

    // used to calculate the per day amount for the calcCredit function
    const perDayAmount = (daysInLieu, violationId) => {
        const currVio = violationRows.filter((v) => v.pkViolationId === violationId)[0]
        const ret = (currVio?.totalFines + currVio?.totalFees + currVio?.totalCosts) / daysInLieu
        return isNaN(ret) || ret === Infinity ? 0.00 : ret;
    }

    // ERRORS STATE TO HANDLE CHECK NUMBER ERRORS
    const [errors, setErrors] = useState({});

    const { caseNumber } = useParams();

    const [paymentRows, setPaymentRows] = useState([
        {
            uuid: crypto.randomUUID(),
            paymentType: modalType === 'quickPayment' ? 'MONEY ORDER' : 'TIME SERVED',
            paymentRef: violation.currentStatute,
            amountRecived: 0,
            displayedAmountRecived: '$0.00',
            paymentAmount: modalType === 'quickPayment' ? balance : violationBalance,
            displayedPaymentAmount: modalType === 'quickPayment' ? formatMoney(balance) : formatMoney(violationBalance),
            change: 0,
            displayBalance: modalType === 'quickPayment' ? formatMoney(balance) : formatMoney(violationBalance),
            balance: modalType === 'quickPayment' ? balance : violationBalance,
            checkNumber: '',
            daysInLieu: violation.daysInLieu,
            timeServed: violation.timeServed,
            paymentOptions: modalType === 'quickPayment' ? paymentOptions.filter(p => p.code !== 'TIME SERVED') : paymentOptions.filter(p => p.code === 'TIME SERVED'),
        },
    ]);

    const addPaymentRow = () => {

        // temp value for adding in a new payment to access values immediately
        const payments = [...paymentRows];

        // Check if any existing payment row has a CASH payment type
        const hasCashPayment = paymentRows.some(row => row.paymentType === 'CASH');

        // Create a new payment row with the appropriate payment options
        const newPaymentOptions = hasCashPayment
            ? paymentOptions.filter(option => option.code !== 'CASH')
            : paymentOptions;

        // Calculate the new balance based on the latest payment in the existing rows
        const latestDisplayBalance = paymentRows.reduce(
            (accumulator, currentRow) => accumulator - currentRow.paymentAmount,
            violationBalance
        );
        const latestBalance = paymentRows.reduce(
            (accumulator, currentRow) => accumulator - currentRow.paymentAmount,
            balance
        );
        // Create a new payment row with the calculated balance
        const newPaymentRow = {
            uuid: crypto.randomUUID(),
            paymentType: modalType !== 'quickPayment' ? 'TIME SERVED' : '',
            paymentRef: violation.currentStatute || violation.chargeType,
            amountRecived: 0,
            displayedAmountRecived: '$0.00',
            paymentAmount: modalType === 'quickPayment' ? latestBalance : latestDisplayBalance,
            displayedPaymentAmount: modalType === 'quickPayment' ? '$' + latestBalance.toFixed(2) : '$' + latestDisplayBalance.toFixed(2),
            change: 0,
            displayBalance: modalType === 'quickPayment' ? latestBalance.toFixed(2) : latestDisplayBalance.toFixed(2),
            balance: modalType === 'quickPayment' ? latestBalance : latestDisplayBalance,
            paymentOptions: modalType === 'quickPayment' ? newPaymentOptions.filter(p => p.code !== 'TIME SERVED') : paymentOptions.filter(p => p.code === 'TIME SERVED'),
        };

        // Add the new payment row to the state
        payments.push(newPaymentRow);
        setPaymentRows([...payments]);
    };

    const handleDeleteRow = (index) => {
        const deletedPaymentAmount = paymentRows[index].paymentAmount;

        const updatedRows = [...paymentRows];
        updatedRows.splice(index, 1);

        setPaymentRows(updatedRows);

        // Convert the displayBalance back to a number for calculations
        const numericDisplayBalance = parseFloat(displayBalance);

        // Update the displayBalance by subtracting the deleted payment amount
        const newDisplayBalance = (numericDisplayBalance + deletedPaymentAmount).toFixed(2);

        setDisplayBalance(newDisplayBalance);
    };

    const handlePaymentTypeChange = (e, payment) => {
        const selectedPaymentType = e.target.value;
        const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
        const newRows = [...paymentRows];

        // Update the payment type for the current row
        newRows[indexByUUID].paymentType = selectedPaymentType;

        // If the selected payment type is 'TIME SERVED', update daysInLieu and timeServed
        if (selectedPaymentType === 'TIME SERVED') {
            newRows[indexByUUID].daysInLieu = parseFloat(violation.daysInLieu);
            newRows[indexByUUID].timeServed = parseFloat(violation.timeServed);
        }

        // Remove the CASH payment option from other payment rows
        if (selectedPaymentType === 'CASH') {
            newRows.forEach((row, index) => {
                if (index !== indexByUUID && row.paymentOptions.some(option => option.code === 'CASH')) {
                    newRows[index] = {
                        ...row,
                        paymentOptions: row.paymentOptions.filter(option => option.code !== 'CASH')
                    };
                }
            });
        } else {
            // If selected payment type is not 'CASH', check if any row has 'CASH' payment type
            const hasCashPayment = newRows.some(row => row.paymentType === 'CASH');
            // If none of the rows have 'CASH' payment type, add 'CASH' back to all payment options arrays
            if (!hasCashPayment) {
                newRows.forEach(row => {
                    row.paymentOptions = [...paymentOptions];
                });
            }
        }

        // Set the updated payment rows
        setPaymentRows(newRows);
    };

    const createTimeServedPayment = async (e) => {
        e.preventDefault();
        if (!paymentBatchNumber) {
            toast.error('Please select a batch for these payments', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (overridedPartialPayment === "Y" && (displayBalance.toString !== '0.00' || newCaseBalance.toString() !== '0.00')) {
            toast.error('Please make full payment', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        } else {
            // Check for errors manually before submitting
            const newErrors = {};
            let toastError;

            paymentRows.forEach((payment, index) => {
                if (payment.timeServed > payment.daysInLieu) {
                    newErrors[index] = "Time Served Cannot Exceed Days In Lieu"
                    toastError = "Time Served Cannot Exceed Days In Lieu"
                }
            });

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                toast.error(toastError, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return;
            }

            try {
                setLoading(true);

                // Prepare payment data array
                const paymentDataArray = paymentRows.map((payment, index) => ({
                    uuid: payment.uuid,
                    FkCaseId: caseNumber,
                    FkViolationId: violationId,
                    PaymentRef: payment.paymentRef,
                    PaymentAmount: payment.paymentAmount,
                    ShiftDate: shiftDate,
                    PaymentType: payment.paymentType,
                    BatchNumber: paymentBatchNumber,
                    CheckNumber: null,
                    index,
                    daysInLieu: payment.daysInLieu,
                    timeServed: payment.timeServed,
                    ChangeGiven: 0,
                    IsPartialPayment: violationBalance > payment.paymentAmount ? 'Y' : 'N',
                    PayToName: remitterState.payToName,
                    PayToAddress1: remitterState.payToAddress1,
                    PayToAddress2: remitterState.payToAddress2,
                    PayToCity: remitterState.payToCity,
                    PayToState: remitterState.payToState,
                    PayToZip: remitterState.payToZip,
                    ConvenienceFee: 0.00,
                    PaymentOffset: 0.00
                }));

                const timeServedPayment = paymentDataArray[0];

                const totalPaymentAmount = timeServedPayment.PaymentAmount;

                if (parseFloat(totalPaymentAmount) < 0.01) {
                    await toast.error('Please enter a valid payment amount', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    return;
                }

                const newPayments = [];
                let masterReceiptNumber;

                const paymentOffset = timeServedPayment.PaymentAmount > violationBalance ? (timeServedPayment.PaymentAmount - violationBalance) : 0.00;
                const newPaymentAmount = timeServedPayment.PaymentAmount > violationBalance ? violationBalance : timeServedPayment.PaymentAmount;

                const newPayment = {
                    ...timeServedPayment,
                    PaymentOffset: paymentOffset,
                    PaymentAmount: newPaymentAmount
                }

                try {
                    const res = await axios.post(`${apiRoute}/api/FfPaymentTbls`, newPayment);
                    masterReceiptNumber = res.data.masterReceipt;

                    const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${res.data.fkCaseId}`);

                    const paymentDataWithId = paymentRes.data.map((row) => ({
                        ...row,
                        id: row.pkFfPaymentId,
                        paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                        dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
                    }));

                    newPayments.push(...paymentDataWithId);

                    if (violation.daysInLieu !== timeServedPayment.daysInLieu && violation.timeServed !== timeServedPayment.timeServed) {
                        await axios.patch(`${apiRoute}/api/ViolationTbls/PostSentence/${violation.pkViolationId}`,
                            {
                                ...violation,
                                daysInLieu: timeServedPayment.daysInLieu,
                                timeServed: timeServedPayment.timeServed
                            });
                    }
                    setCaseHistoryRows(newPayments);
                    await updateCaseBalance();
                    setReceiptNumber(masterReceiptNumber);
                    setMasterReceipt(masterReceiptNumber);
                    handleOpen('printReceipt', '30%');
                    await toast.success('Payments added', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } catch (e) {
                    setLoading(false);
                    if (e?.response?.data === 'Partial Payment is not allowed on this violation.') {
                        await toast.error('Partial Payment is not allowed on this violation.', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        await toast.error('Payment failed. Please Reach Out To Support.', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                }
            } catch (err) {
                await toast.error(`Payment failed. Please Reach Out To Support`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } finally {
                setLoading(false);
            }
        }
    };



    const createNonTimeServedPayment = async (e) => {
        e.preventDefault();
        if (!paymentBatchNumber) {
            toast.error('Please select a batch for these payments', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }

        if (overridedPartialPayment === "Y" && (displayBalance.toString !== '0.00' || newCaseBalance.toString() !== '0.00')) {
            toast.error('Please make full payment', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        } else {
            // Check for errors manually before submitting
            const newErrors = {};
            let toastError;

            paymentRows.forEach((payment, index) => {
                if ((payment.paymentType === 'CHECK' || payment.paymentType === 'MONEY ORDER') && !payment.checkNumber) {
                    newErrors[index] = "Check Number Required"
                    toastError = "Check Number Required"
                }
            });

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                toast.error(toastError, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return;
            }

            try {
                setLoading(true);
                // Prepare payment data array
                const paymentDataArray = paymentRows.map((payment, index) => ({
                    uuid: payment.uuid,
                    FkCaseId: caseNumber,
                    FkViolationId: violationId,
                    PaymentRef: payment?.paymentRef,
                    PaymentAmount: payment.paymentAmount,
                    ShiftDate: shiftDate,
                    PaymentType: payment.paymentType,
                    BatchNumber: paymentBatchNumber,
                    CheckNumber: ['CHECK', 'MONEY ORDER', 'CASHIERS CHECK'].includes(payment.paymentType) ? payment.checkNumber : null,
                    index,
                    daysInLieu: payment?.daysInLieu,
                    timeServed: payment?.timeServed,
                    ChangeGiven: (payment.paymentType === 'CASH' && payment?.amountRecived > payment?.balance) ? payment?.amountRecived - payment?.balance : 0,
                    IsPartialPayment: overridedPartialPayment,
                    PayToName: remitterState?.payToName,
                    PayToAddress1: remitterState?.payToAddress1,
                    PayToAddress2: remitterState?.payToAddress2,
                    PayToCity: remitterState?.payToCity,
                    PayToState: remitterState?.payToState,
                    PayToZip: remitterState?.payToZip,
                    ConvenienceFee: 0.00,
                    PaymentOffset: 0.00
                }));

                const quickPaymentObj = {
                    Violations: right,
                    ffPaymentVm: paymentDataArray,
                }

                const totalPaymentAmount = paymentDataArray.reduce((total, payment) => total + payment.PaymentAmount, 0);

                if (parseFloat(totalPaymentAmount) < 0.01) {
                    toast.error('Please enter a valid payment amount', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    return;
                }


                try {
                    let newPayments = [];
                    await axios.post(`${apiRoute}/api/ffpaymenttbls/quick`, quickPaymentObj);

                    const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
                    const paymentDataWithId = paymentRes.data.map((row) => ({
                        ...row,
                        id: row.pkFfPaymentId,
                        paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                        dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
                    }));
                    newPayments.push(...paymentDataWithId);

                    setCaseHistoryRows(newPayments);
                    await updateCaseBalance();
                    setReceiptNumber(newPayments[newPayments.length - 1].masterReceipt);
                    setMasterReceipt(newPayments[newPayments.length - 1].masterReceipt);
                    handleOpen('printReceipt', '30%');
                    toast.success('Payments added', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } catch (e) {
                    setLoading(false);
                    if (e?.response?.data === 'Partial Payment is not allowed on this violation.') {
                        await toast.error('Partial Payment is not allowed on this violation.', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    } else {
                        await toast.error('Payment failed. Please Reach Out To Support.', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                }

            } catch (err) {
                await toast.error(`Payment failed. Please Reach Out To Support`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    // Calculate change and balance
    useEffect(() => {
        // This section forces Cash to be the last entry in the list
        const isCash = (pmt) => pmt?.paymentType === 'CASH';
        let cashIndex = paymentRows.findIndex(isCash);
        if (cashIndex >= 0 && cashIndex < paymentRows.length - 1) {
            paymentRows.push(paymentRows.splice(cashIndex, 1)[0]);
            // If we bump the cash row, we need to recalc the balances.
            let currentBalance = 0;
            const updatedRows = paymentRows.map((payment, index) => {
                payment.balance = violationBalance - currentBalance;
                payment.displayBalance = formatMoney(payment.balance);
                currentBalance += payment.paymentAmount;
            });
            setPaymentRows(updatedRows);
        }

        const updatedRows = paymentRows.map((payment, index) => {
            let rowChange = 0;
            let rowDisplayBalance = payment?.displayBalance;

            if (payment?.paymentType === 'CASH') { // We're not running a check cashing center.  Only cash gets change.      	
                payment.paymentAmount = Math.min(payment.balance, payment.amountRecived);
                //payment.displayedPaymentAmount = commaMoneyFormat(payment.paymentAmount);
                payment.paymentAmount = payment.amountRecived;
                payment.amountRecived = payment.paymentAmount;
                payment.amountReceived = payment.paymentAmount;
                payment.displayBalance = (payment?.balance - payment?.amountRecived).toFixed(2);
                setDisplayBalance(payment?.balance - payment?.amountRecived)?.toFixed(2);
                payment.change = newCaseBalance > 0.00 ? payment?.paymentAmount - payment?.amountRecived : payment?.paymentAmount + newCaseBalance
                let change;

                if (modalType === 'quickPayment') {
                    if (newCaseBalance > 0.00) {
                        change = payment.paymentAmount - payment.amountRecived;
                    } else {
                        change = newCaseBalance * -1;
                    }
                } else if (modalType === 'newPayment') {
                    if (displayBalance > 0.00) {
                        change = payment.paymentAmount - payment.amountRecived;
                    } else {
                        change = displayBalance * -1;
                    }
                }
                payment.displayedPaymentAmount = newCaseBalance < 0.00 ? commaMoneyFormat(payment.amountRecived + newCaseBalance) : commaMoneyFormat(payment.amountRecived - change);
                if (change > 0) {
                    rowDisplayBalance = (payment.balance - payment.paymentAmount).toFixed(2);
                } else {
                    change = 0;
                    rowDisplayBalance = change.toFixed(2);
                }
                rowChange = change;
                setChange(change);

            } else if (payment?.paymentType === "MONEY ORDER" || payment?.paymentType === "CHECK") {
                payment.paymentAmount = payment.amountRecived;
                payment.amountRecived = payment.paymentAmount;
                payment.amountReceived = payment.paymentAmount;
                payment.displayedPaymentAmount = commaMoneyFormat(payment.paymentAmount);
                payment.displayBalance = (payment.balance - payment.amountRecived).toFixed(2);
                setDisplayBalance(payment?.balance - payment?.amountRecived)?.toFixed(2);

            } else if (payment?.paymentType === 'TIME SERVED') {
                payment.amountRecived = payment.paymentAmount;
                payment.amountReceived = payment.paymentAmount;
                payment.displayedPaymentAmount = commaMoneyFormat(payment.paymentAmount);
                payment.paymentAmount = payment.amountRecived;
                payment.displayBalance = (payment.balance - payment.amountRecived).toFixed(2);
                setDisplayBalance(payment?.balance - payment?.amountRecived)?.toFixed(2);
            }
            if (rowDisplayBalance < 0.00) {
                rowDisplayBalance = 0.00
            }
            setDisplayBalance(rowDisplayBalance);

            return {
                ...payment,
                change: rowChange,
                displayBalance: rowDisplayBalance,
            };

            return payment; // No changes
        });

        // Update the paymentRows state only if there's a change in the calculated values
        const isDifferent = updatedRows.some(
            (updatedPayment, index) =>
                updatedPayment?.change !== paymentRows[index]?.change ||
                updatedPayment?.displayBalance !== paymentRows[index]?.displayBalance
        );
        if (isDifferent) {
            setPaymentRows(updatedRows);
        }
        // Store the updated payment amounts in the ref
        //prevPaymentAmountsRef.current = prevPaymentAmounts;
    }, [paymentRows, newCaseBalance, violationBalance, displayBalance]);

    // Calculate remaing balance based off amount to apply total for quick payments
    useEffect(() => {
        let updatedBalance = grandTotal();
        let total = 0.00;
        if (modalType === 'quickPayment') {
            paymentRows.forEach((payment) => {
                updatedBalance -= payment?.amountRecived;
                total += payment?.amountRecived;
            });
            setNewCaseBalance(updatedBalance);
            setTotalPayment(total);
        }
        setGrand(grandTotal());
    }, [paymentRows, newCaseBalance]);

    useEffect(() => {
        let updatedBalance = violationBalance
        if (modalType !== 'quickPayment') {
            paymentRows.forEach((payment) => {
                updatedBalance -= payment.amountRecived
            })
            setDisplayBalance(updatedBalance);
        }
    }, [paymentRows, violationBalance, modalType]);

    // checks the openBatches array to see if there is only 1 batch, if there is set the paymentBatchNumber to that batch number
    useEffect(() => {
        if (openBatches.length === 1) {
            const { batchNumber } = openBatches[0];
            setPaymentBatchNumber(batchNumber);
        }
    }, [openBatches, setPaymentBatchNumber]);

    // handle change for time served data/calculations
    const handleTimeServedChanges = (daysInLieu, timeServed, i, violationId) => {
        const paymentAmt = calcCredit(daysInLieu, timeServed, violationId);
        paymentRows[i].amountReceived = parseFloat(paymentAmt);
        paymentRows[i].amountRecived = parseFloat(paymentAmt);
        paymentRows[i].displayedAmountRecived = '$' + parseFloat(paymentAmt).toFixed(2);
        paymentRows[i].displayedPaymentAmount = '$' + parseFloat(paymentAmt).toFixed(2)
        paymentRows[i].paymentAmount = paymentAmt.toFixed(2);
        setDisplayBalance((paymentRows[i].balance - paymentAmt).toFixed(2));
    };

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(violationRows.filter(v => v.remainingBalance >= 0.01 && v.amendedDate == null).map(v => v));
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const maxHeight = modalType === 'newPayment' ? '30vh' : '35vh';

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const customList = (title, items) => {
        return (
            <Card overflowY={'auto'}>
                <CardHeader
                    sx={{ px: 1, py: 1 }}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'all items selected',
                            }}
                        />
                    }
                    title={title}
                    subheader={`${right.length}/${violationRows.filter(v => v.remainingBalance > 0.00 && v.amendedDate == null).length} violations selected`}
                />
                <Divider />
                <List
                    sx={{
                        height: 230,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {items.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value.id}-label`;
                        return (
                            <ListItemButton
                                key={value.pkViolationId}
                                role="listitem"
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={value.description}
                                    secondary={
                                        title === "Violations" ? (
                                            <>
                                                Statute: {violationRows.find((v) => v.pkViolationId === value.pkViolationId)?.currentStatute}
                                                <br />
                                                Remaining Balance: {new Intl.NumberFormat('en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        maximumFractionDigits: 2
                                                    }).format(violationRows.find((v) => v.pkViolationId === value.pkViolationId && v.remainingBalance >= 0.01)?.remainingBalance)}
                                            </>
                                        ) : (
                                            <>
                                                Pay Order: {index + 1}
                                                <br />
                                                Statute: {violationRows.find((v) => v.pkViolationId === value.pkViolationId)?.currentStatute}
                                                <br />
                                                Remaining Balance: {new Intl.NumberFormat('en-US',
                                                    {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        maximumFractionDigits: 2
                                                    }).format(violationRows.find((v) => v.pkViolationId === value.pkViolationId && v.remainingBalance >= 0.01)?.remainingBalance)}
                                            </>
                                        )
                                    }
                                />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Card>
        );
    }

    const grandTotal = () => {
        const violations = violationRows.filter(v => right.some(r => v.pkViolationId === r.pkViolationId));
        const total = violations.reduce((acc, v) => {
            return acc += v.remainingBalance;
        }, 0)
        return total;
    };
    return (
        (<form onSubmit={(e) => { modalType === 'newPayment' ? createTimeServedPayment(e) : createNonTimeServedPayment(e) }} style={{ padding: '.5vh' }}>
            <>
                {
                    modalType === 'quickPayment' ?
                        (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
                                    <Grid sx={{ width: '40%' }} container>
                                        <RemitterForm
                                            ts={ts}
                                            dispatch={remitterDispatch}
                                            state={remitterState}
                                            modalType={modalType}
                                            citationState={citationState}
                                        />
                                    </Grid>
                                    <Box sx={{ marginLeft: '1vw', pb: '1vh', width: '60%' }}>
                                        <Paper sx={{ mb: '1vh' }} elevation={10}>
                                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }} elevation={10}>
                                                <h6 style={{ marginBottom: '0px' }}>Violations And Pay Order</h6>
                                            </Paper>
                                            <Box sx={{ height: 'auto' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{ padding: '1vh', paddingLeft: '1vw', paddingTop: '1vh', paddingBottom: '.5vh', marginBottom: 0, width:"-webkit-fill-available" }}>Select Violations To Pay Against</p>
                                                <TextField
                                                    sx={{
                                                         padding: '1vh', paddingLeft: '1vw', paddingTop: '1vh', paddingBottom: '.5vh', marginBottom: 0, 
                                                        
                                                    }}
                                                    id="totalAmount"
                                                    size="small"
                                                    hiddenLabel
                                                    variant="standard"
                                                    fullWidth
                                                    inputProps={{ readOnly: true, textAlign: 'right' }}
                                                    InputProps={{
                                                        notched: false,
                                                        style: { alignItems: 'right' },
                                                        disableUnderline: true

                                                    }}
                                                    value={`Total Amount due: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(grandTotal())}`}
                                                />
                                                </div>
                                                <hr style={{ marginTop: 4, marginLeft: '.5vw' }} />
                                                <Grid container spacing={1.5} justifyContent="center" alignItems="center" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
                                                    
                                                    <Grid sx={{ width: '40%', ml: '1vh',mt:'1vh', mb: '3vh', mr:'1vh' }} item>{customList('Violations', left)}</Grid>
                                                        <Grid item>
                                                            <Grid sx={{ width:'20%',display: 'flex', justifyContent: 'center', mt:0}} container direction="column" alignItems="center">
                                                                <Button
                                                                    sx={{ my: 0.5, width: '2vh' }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={handleCheckedRight}
                                                                    disabled={leftChecked.length === 0}
                                                                    aria-label="move selected right"
                                                                >
                                                                    &gt;
                                                                </Button>
                                                                <Button
                                                                    sx={{ my: 0.5, width: '2vh' }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={handleCheckedLeft}
                                                                    disabled={rightChecked.length === 0}
                                                                    aria-label="move selected left"
                                                                >
                                                                    &lt;
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    <Grid sx={{ width: '40%', m: '1vh', mb: '3vh' }} item>{customList('Pay Order', right)}</Grid>
                                                   
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Box>

                                </Box>
                            </>
                        ) : (
                            <RemitterForm
                                ts={ts}
                                dispatch={remitterDispatch}
                                state={remitterState}
                                modalType={modalType}
                                citationState={citationState}
                            />
                        )
                }
            </>
            <Paper elevation={10} mb={1}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: 'auto', display:'flex' }} elevation={10}>
                    <h6 style={{ marginBottom: '0px' }}>Payment Info</h6>
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', overflow: 'hidden' }}>
                    <h5 style={{ marginTop: '1vh', marginLeft: '1rem' }}>Batches: </h5>&nbsp;&nbsp;
                    <Box sx={{ width: '100%' }}>
                        <RadioGroup
                            row
                            name='searchFilter'
                            value={paymentBatchNumber}
                            onChange={(e) => setPaymentBatchNumber(e.target.value)}
                        >
                            {
                                // destructured batchNumber out of batch object to make this easier to read
                                openBatches.sort((a, b) => a.batchNumber < b.batchNumber ? -1 : 1).map(({ batchName, batchNumber, batchType }) => (
                                    batchType === "SIMPLE" &&
                                    (<FormControlLabel
                                        key={batchNumber}
                                        value={batchNumber}
                                        control={<Radio />}
                                        label={batchName ? `${batchName}(${batchNumber})` : batchNumber}
                                    />)
                                ))
                            }
                        </RadioGroup>
                    </Box>
                </Box>
                <hr />
                <Box sx={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                    <Grid container direction="row" sx={{ display: "inline-block", verticalAlign: 'middle' }}>
                        {paymentRows?.map((payment, index) => (
                            <React.Fragment key={payment?.uuid}>
                                <Box sx={{ display: "flex", alignItems: 'center', mt: "1vh" }}>
                                    <Grid item sm={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '50px' }}>
                                            <Tooltip title="Delete Payment" placement="top">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleDeleteRow(index)}
                                                    sx={{ backgroundColor: 'steelblue', color: 'white', m: 'auto', height: '100%', fontSize: '20px' }}
                                                >
                                                    <RiDeleteBinLine />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Box m={.5}>
                                            <TextField
                                                sx={ts}
                                                select
                                                id="paymentType"
                                                label="Type"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={payment?.paymentType}
                                                onChange={(e) => handlePaymentTypeChange(e, payment)}
                                            >
                                                {
                                                    payment?.paymentOptions?.map(({ code, description }) => {
                                                        return <MenuItem key={code} value={code}>{description}</MenuItem>;
                                                    })
                                                }
                                            </TextField>
                                        </Box>
                                    </Grid>
                                    {
                                        modalType === 'newPayment' &&
                                        <Grid item sm={1.25}>
                                            <Box m={.5}>
                                                <TextField
                                                    sx={ts}
                                                    id="paymentRef"
                                                    label="Reference"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    value={paymentRef}
                                                    inputProps={{ readOnly: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MdEditOff />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    }
                                    {payment?.paymentType === "TIME SERVED" ? (
                                        <>
                                            <Grid item sm={1.25}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        id={`daysInLieu-${index}`}
                                                        label="Days In Lieu"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                        value={payment.daysInLieu || 0}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (!isNaN(value) || value !== '') {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].daysInLieu = parseFloat(value);
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                            const newRows = [...paymentRows];
                                                            if (isNaN(value) || value === '') {
                                                                newRows[indexByUUID].daysInLieu = 0;
                                                                setPaymentRows(newRows);
                                                            } else {
                                                                violation.daysInLieu = newRows[indexByUUID].daysInLieu
                                                            }
                                                            handleTimeServedChanges(parseFloat(value), newRows[indexByUUID].timeServed, indexByUUID, violationId)
                                                        }}
                                                        error={payment.daysInLieu < payment.timeServed}
                                                        helperText={payment.daysInLieu < payment.timeServed && "Time Served Cannot Exceed Days In Lieu"}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={1.75}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        id={`timeServed-${index}`}
                                                        label="Time Served"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                        value={payment.timeServed || 0}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (!isNaN(value)) {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].timeServed = parseFloat(value)
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                            const newRows = [...paymentRows];
                                                            if (isNaN(value) || value === '') {
                                                                newRows[indexByUUID].timeServed = 0;
                                                                setPaymentRows(newRows);
                                                            } else {
                                                                violation.timeServed = newRows[indexByUUID].timeServed;
                                                            }
                                                            handleTimeServedChanges(newRows[indexByUUID].daysInLieu, newRows[indexByUUID].timeServed, indexByUUID, violationId)
                                                        }}
                                                        error={payment.daysInLieu < payment.timeServed}
                                                        helperText={payment.daysInLieu < payment.timeServed && "Time Served Cannot Exceed Days In Lieu"}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        fullWidth
                                                        id={`creditApplied-${index}`}
                                                        label="Credit Applied"
                                                        variant="outlined"
                                                        value={formatMoney(parseFloat(calcCredit(payment.daysInLieu, payment.timeServed, violationId)))}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            readOnly: true,
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MdEditOff />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        fullWidth
                                                        id={`creditApplied-${index}`}
                                                        label="Per Day Total"
                                                        variant="outlined"
                                                        value={formatMoney(parseFloat(perDayAmount(payment.daysInLieu, violationId)))}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            readOnly: true
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MdEditOff />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item sm={1.75}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        id={`amountRecived-${index}`}
                                                        label="Amount Received"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                        value={payment?.displayedAmountRecived}
                                                        onClick={() => {
                                                            // Clear the input field when clicked
                                                            const newRows = [...paymentRows];
                                                            newRows[index].displayedAmountRecived = '$';
                                                            setPaymentRows(newRows);
                                                        }}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (!isNaN(value)) {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].amountRecived = parseFloat(value);
                                                                newRows[indexByUUID].displayedAmountRecived = '$' + value;
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (isNaN(value) || value === '') {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].amountRecived = 0;
                                                                newRows[indexByUUID].displayedAmountRecived = '$0.00';
                                                                setPaymentRows(newRows);
                                                            } else {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].displayedAmountRecived = commaMoneyFormat(value);
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={1.75}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        id={`paymentAmount-${index}`}
                                                        label="Amount To Apply"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                        value={payment?.displayedPaymentAmount}
                                                        onClick={() => {
                                                            // Clear the input field when clicked
                                                            const newRows = [...paymentRows];
                                                            newRows[index].displayedPaymentAmount = '$';
                                                            setPaymentRows(newRows);
                                                        }}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (!isNaN(value)) {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].paymentAmount = parseFloat(value);
                                                                newRows[indexByUUID].displayedPaymentAmount = value;
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                            if (isNaN(value) || value === '') {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].paymentAmount = 0;
                                                                newRows[indexByUUID].displayedPaymentAmount = '$0.00';
                                                                setPaymentRows(newRows);
                                                            } else {
                                                                const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                const newRows = [...paymentRows];
                                                                newRows[indexByUUID].displayedPaymentAmount = commaMoneyFormat(value);
                                                                setPaymentRows(newRows);
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Box m={.5}>
                                                    <TextField
                                                        sx={ts}
                                                        id={`change-${index}`}
                                                        label="Change"
                                                        variant="outlined"
                                                        value={formatMoney(payment?.change || 0.00)}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            readOnly: true
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MdEditOff />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    )
                                    }
                                    <Grid item sm={2}>
                                        {(payment?.paymentType === "MONEY ORDER" || payment?.paymentType === "CHECK") && (
                                            <Box m={.5}>
                                                <Tooltip title={errors?.[index] || ''} open={!!errors?.[index]} placement="top">
                                                    <TextField
                                                        sx={ts}
                                                        id={`checkNumber-${index}`}
                                                        label="Check Number"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={payment?.checkNumber}
                                                        onChange={(e) => {
                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                            const newRows = [...paymentRows];
                                                            newRows[indexByUUID].checkNumber = e.target.value.replace(/[^0-9.]/g, '');
                                                            setPaymentRows(newRows);
                                                            if (e.target.value === '') {
                                                                setErrors({
                                                                    ...errors,
                                                                    [indexByUUID]: 'Check Number Required'
                                                                })
                                                            } else {
                                                                setErrors({
                                                                    ...errors,
                                                                    [indexByUUID]: undefined
                                                                })
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                            if (e.target.value === '') {
                                                                setErrors({
                                                                    ...errors,
                                                                    [indexByUUID]: 'Check Number Required'
                                                                })
                                                            } else {
                                                                setErrors({
                                                                    ...errors,
                                                                    [indexByUUID]: undefined
                                                                })
                                                            }
                                                        }}
                                                        error={!!errors?.[index]}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        )}
                                    </Grid>
                                </Box >
                            </React.Fragment >
                        ))}
                    </Grid >
                </Box >
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: "1vh", alignItems: "flex-end" }}>
                    <Box sx={{ width: "30%", mb: '1vh' }}>
                        <Tooltip
                            title="A payment type is needed on the payment before adding a new payment"
                            placement="top"
                            arrow
                            disableHoverListener={!paymentRows.some((row) => row?.paymentType === '')}
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    sx={{ m: ".5vh", mt: "0", width: '95%', backgroundColor: 'steelblue', color: 'white' }}
                                    onClick={addPaymentRow}
                                    disabled={paymentRows.some((row) => row?.paymentType === '' | row?.paymentType === 'TIME SERVED')}
                                >
                                    Add Payment
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                    <Box sx={{ marginLeft: 'auto', mb: '1vh' }}>
                        <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} type="submit" disabled={paymentRows.some((row) =>  row?.paymentType !== 'TIME SERVED') ? (loading | right.length < 1) : loading}>Submit Payment</Button>
                        <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>
            </Paper >
        </form >)
    );
}
export default PaymentForm;