import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios'
import { Box, Paper, Button, Backdrop, CircularProgress, Modal } from '@mui/material';

import { apiRoute, ThemeModeContext } from '../App';
import DistributionCheckProvider from '../context/DistributionCheckContext';
import DisbursementViewTable from '../components/tables/views/DisbursementViewTable';
import { formatDateCST, formattedDate, formatTime, formatTimeCST } from '../Utils';
import PrintDisbursementChecksModal from '../components/navbar/ReportsModals/PrintDisbursementChecksModal';
import DisbursementDetailsModal from '../components/modals/views/DisbursementDetailsModal';

const CloseOutMonth = () => {
    const { mode } = useContext(ThemeModeContext);

    const [selectedCheck, setSelectedCheck] = useState({});
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [from, setFrom] = useState('')

    const [modal, setModal] = useState(false);
    const [disburseDates, setDisburseDates] = useState([]);
    const [loadingDates, setLoadingDates] = useState(true);
    const formattedDefaultDate = new Date().toISOString().split('T')[0];
    const [checkDate, setCheckDate] = useState(formattedDefaultDate);
    const [checkNumber, setCheckNumber] = useState(0);
    const [printDisbursementChecksModal, setPrintDisbursementChecksModal] = useState(false);
    const [retrievedData, setRetrievedData] = useState([])

    const handlePrintDisbursementChecksModalOpen = () => setPrintDisbursementChecksModal(true);
    const handlePrintDisbursementChecksModalClose = () => setPrintDisbursementChecksModal(false);

    const handleOpen = () => { setFrom('preDisburse'); setModal(true) };
    const handleClose = () => { setFrom(''); setModal(false) };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const getDisburseReconDates = () => {
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.sort((a, b) => {
                    if (a.masterDisburseId > b.masterDisburseId) {
                        return -1;
                    } else if (a.masterDisburseId < b.masterDisburseId) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formatDateCST(row.disburseDate),
                        Time: formatTimeCST(row.disburseDate),
                        EnteredBy: row.enteredBy,
                        Value: row.disburseDate,
                        DisburseId: row.masterDisburseId,
                        TotalDisbursed: row.totalDisbursed
                    }
                })
                setDisburseDates(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
            })
            .finally(() => setLoadingDates(false))
    };

    useEffect(() => {
        getDisburseReconDates();
    }, [from]);

    return (
        <DistributionCheckProvider>
            <Box sx={{ width: '60%', m: '1vw auto', height: '100%' }}>
                <Paper sx={{ height: 'auto', pb: '.1vh' }} elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '1vh', textAlign: 'center' }} elevation={10}>
                        <h1 style={{ fontWeight: 'bold' }}>Close Out Month</h1>
                    </Paper>
                    <Box sx={{ width: '100%', m: '1vh auto', p: 1 }}>
                        <DisbursementViewTable
                            disburseDates={disburseDates}
                            setDisburseDates={setDisburseDates}
                            loadingDates={loadingDates}
                            setLoadingDates={setLoadingDates}
                            getDisburseReconDates={getDisburseReconDates}
                            from={from}
                            setFrom={setFrom}
                            setOpenModal={setModal}
                            setRetrievedData={setRetrievedData}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: '1.5vh' }}>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleOpen}>New Disbursement</Button>
                        </Box>
                    </Box>
                </Paper>
                <Modal open={modal} sx={{ width: from === 'preDisburse' ? '30%' : '70%', m: '5vh auto' }}>
                    <>
                        <DisbursementDetailsModal
                            handleClose={handleClose}
                            ts={textFieldStyle}
                            from={from}
                            setFrom={setFrom}
                            getDisburseReconDates={getDisburseReconDates}
                            printDisbursementCheckModal={printDisbursementChecksModal}
                            handlePrintDisbursementChecksModalOpen={handlePrintDisbursementChecksModalOpen}
                            handlePrintDisbursementChecksModalClose={handlePrintDisbursementChecksModalClose}
                            retrievedData={retrievedData}
                        />
                    </>
                </Modal>
                <Modal
                    open={printDisbursementChecksModal}
                    onClose={handlePrintDisbursementChecksModalClose}
                    aria-labelledby="disbursment-checks-modal"
                    sx={{ width: '35%', m: '5vh auto' }}
                >
                    <>
                        <PrintDisbursementChecksModal handleClose={handlePrintDisbursementChecksModalClose} checkDate={checkDate}
                            setCheckDate={setCheckDate} />
                    </>
                </Modal>
            </Box>
            {
                loadingCheck && (
                    <Backdrop open sx={{ zIndex: 1 }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <p>
                                <CircularProgress />
                            </p>
                        </div>
                    </Backdrop>
                )
            }
        </DistributionCheckProvider>
    )
}

export default CloseOutMonth;