import React, { useState } from 'react';
import smsOptin from '../images/smsOptin.png';
import { Box, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TextOptIn = () => {
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [number, setNumber] = useState('');
    const [optIn, setOptIn] = useState(false);

    const handleSubmit = () => {
        return toast.success('Successfully opted in to receive SMS', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return (
        /*<img src={smsOptin} alt='smsOptIn' />*/
        (<Box sx={{ display: 'flex', justifyContent: 'center', mt: '5vh' }}>
            <Box>
                <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                    <h4>Payment Receipt Option</h4>
                </Box>
                <Box sx={{ my: '1vh' }}>
                    <TextField
                        fullWidth
                        label="First Name"
                        id="first"
                        value={first}
                        onChange={(e) => setFirst(e.target.value)}
                    />
                </Box>
                <Box sx={{ my: '1vh' }}>
                    <TextField
                        fullWidth
                        label="Last Name"
                        id="last"
                        value={last}
                        onChange={(e) => setLast(e.target.value)}
                    />
                </Box>
                <Box sx={{ my: '1vh' }}>
                    <TextField
                        fullWidth
                        label="Phone #(USA Only)"
                        id="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={optIn} onChange={(e) => setOptIn(e.target.checked)} />}
                    label="Select this box to verify receiving your payment receipt via SMS"
                />
                <Button variant="contained" onClick={handleSubmit} disabled={!optIn}>Opt In</Button>
            </Box>
        </Box>)
    );
}

export default TextOptIn;