import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, MenuItem, Paper, TextField, Grid } from '@mui/material';
import DisburseDatePicker from './DisburseDatePicker';

import { ThemeModeContext, apiRoute } from '../../App'; // fix route here

const ReportPicker = (props) => {
    const {
        reportName,
        startDate,
        endDate,
        switchReports,
        textFieldStyle,
        reportOptions,
        setReportName,
        setStartDate,
        setEndDate,
        reportsCategory,
        setReportsCategory,
        disburseDatesList,
        loadingDates,
        setDisburseDate,
        setPreviousDate,
        setDisburseId,
        disburseId
    } = props

    const generateDisabled = reportsCategory === 'collection' ? reportName === 'none' || !startDate || !endDate || new Date(startDate) > new Date(endDate) : reportName === 'none' && !disburseId;

    return (
        <Grid container alignItems="center" spacing={0} columnSpacing={0} sx={{ mt: '3vh', ml: '1.5vw' }}>
            <Grid sm={12} sx={{ ml: '8.15vw' }} >
                <TextField
                    label="Category Select"
                    select
                    value={reportsCategory}
                    onChange={(e) => { setReportsCategory(e.target.value); setReportName('none'); }}
                    sx={{ ...textFieldStyle, width: '15vw', textAlign: 'center' }}
                >
                    <MenuItem
                        key="collection"
                        value={"collection"}
                    >
                        collection
                    </MenuItem>
                    <MenuItem
                        key="disbursement"
                        value="disbursement"
                    >
                        disbursement
                    </MenuItem>
                </TextField>
                <TextField
                    label="Report Select"
                    select
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                    sx={{ ...textFieldStyle, mt: '3vh', width: '15vw', textAlign: 'center' }}
                >
                    {
                        reportOptions.filter(report => report.category === reportsCategory).map(report => (
                            <MenuItem
                                key={report.key}
                                value={report.name}

                            >
                                {report.name}
                            </MenuItem>
                        ))

                    }
                </TextField>
            </Grid>
            <Grid sm={12} sx={{ mt: '3vh', ml: '11.75vw' }} >
                <h5 sx={{ textAlign: 'center' }}>Report Dates</h5>
            </Grid>
            {reportsCategory === "collection" &&
            <>
            <Grid sm={12} sx={{ mt: '3vh', ml: '8.15vw' }}>
                <TextField
                    type="date"
                    id="startDateRange"
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    sx={{ ...textFieldStyle, width: '15vw' }}
                />
            </Grid>
            <Grid sm={12} sx={{ mt: '3vh', ml: '8.15vw' }}>
                <TextField
                    type="date"
                    id="endDateRange"
                    label="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    sx={{ ...textFieldStyle, width: '15vw' }}
                />
            </Grid>
            </>
            }
            {reportsCategory === 'disbursement' && 
                <Grid sm={12} sx={{ mt: '3vh', ml: '1vw' }}>
                <DisburseDatePicker
                        disburseDatesList={disburseDatesList}
                        loadingDates={loadingDates}
                        setDisburseDate={setDisburseDate}
                        setPreviousDate={setPreviousDate}
                        setDisburseId={setDisburseId}
                    />
                </Grid>
            }
            <Grid sm={12} sx={{ mt: '3vh', ml: '11.75vw' }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: "steelblue", color: "white" }}
                    onClick={switchReports}
                    disabled={generateDisabled}
                >
                    Generate Report
                </Button>
            </Grid>
        </Grid>
    )
}

export default ReportPicker;