import React, { useState, useCallback, useContext, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Box, Button, Divider, LinearProgress, TextField, Tooltip } from '@mui/material';
import { GridRowModes, DataGridPremium, GridActionsCellItem, GridRowEditStartReasons, GridRowEditStopReasons } from '@mui/x-data-grid-premium';


import { apiRoute } from '../../../../App';
import { DistributionCheckContext } from '../../../../context/DistributionCheckContext';
import { CaseContext } from '../../../../context/CaseContext';
import { stateOptions } from '../../../../Utils';

const BondRefundChecksModal = (props) => {
    // state variables and props
    const {
        handleClose,
        selectedBond,
        checkInfo,
        voidBondCheck,
        setCheckNum,
        loadingPrint
    } = props;

    const [refundRowModesModel, setRefundRowModesModel] = useState({});

    // component methods
    const printCheck = async (checkId) => {
        const pdfReportResponseHandler = (response) => {
            const arrayBufferView = new Uint8Array(response.data);
            const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
            const dataUrl = URL.createObjectURL(blob);
            handleClose();
            window.open(dataUrl, 'BondCheck');
        };
        try {
            const response = await axios.get(`${apiRoute}/api/PrintDisbursementChecks/Print?checkId=${checkId}&checkDate=${new Date().toISOString()}`, { responseType: 'arraybuffer' });
            pdfReportResponseHandler(response);
        } catch (error) {
            console.error('error printing bond refund check:', error)

        }
    }

    // DataGrid related items
    const handleRefundRowModesModelChange = (newRowModesModel) => {
        setRefundRowModesModel(newRowModesModel);
    };

    const handleRefundRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => {
        setRefundRowModesModel({ ...refundRowModesModel, [id]: { mode: GridRowModes.Edit } })
    };

    const handleSaveClick = (id) => {
        setRefundRowModesModel({ ...refundRowModesModel, [id]: { mode: GridRowModes.View } })
    };

    const handleCancelClick = (id) => {
        setRefundRowModesModel({
            ...refundRowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true }
        });
    };

    const isInEditMode = (id) => {
        return refundRowModesModel[id]?.mode === GridRowModes.Edit;
    }

    const processRefundRowUpdate = useCallback(
        async (updatedRefund, originalRefund) => {
            const checkAmount = originalRefund.checkAmount;
            const requestObj = {
                Id: originalRefund.id,
                PayToAddress1: updatedRefund.payToAddress1,
                PayToAddress2: updatedRefund.payToAddress2,
                PayToCity: updatedRefund.payToCity,
                PayToName: updatedRefund.payToName,
                PayToState: updatedRefund.payToState,
                PayToZip: updatedRefund.payToZip,
                CheckNumber: updatedRefund.checkNumber,
                SourceId: originalRefund.sourceId,
                PayeeId: originalRefund.payeeId
            }

            // await axios request
            const { data } = await axios.put(`${apiRoute}/api/bond/refundCheck`, requestObj);
            data.checkAmount = checkAmount;
            return data
            // find row and replace with updatedRefundCheck
            // Rest
        }, [])

    const handleProcessRefundRowUpdateError = useCallback((error) => {
        toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
    })

    const columns = [
        {
            field: 'checkNumber',
            headerName: 'Check#',
            width: 100,
            editable: true,
        },
        {
            field: 'payToName',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'payToAddress1',
            headerName: 'Address',
            width: 150,
            editable: true,
        },
        {
            field: 'payToAddress2',
            headerName: 'Line 2',
            width: 150,
            editable: true
        },
        {
            field: 'payToCity',
            headerName: 'City',
            width: 125,
            editable: true,
        },
        {
            field: 'payToState',
            headerName: 'State',
            width: 75,
            editable: true,
            type: 'singleSelect',
            valueOptions: stateOptions
        },
        {
            field: 'payToZip',
            headerName: 'ZIP Code',
            width: 150,
            editable: true
        },
        {
            field: 'checkAmount',
            headerName: 'Amount',
            align: 'right',
            headerAlign: 'right',
            width: 100,
            renderCell: (params) => {
                return (<>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>)
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerAlign: 'center',
            width: 275,
            renderCell: (params) => {
                const { id, row } = params;
                return (
                    !isInEditMode(id) ?
                        <>
                            <Tooltip title={row?.transactions[0]?.isVoid && `Check # ${checkInfo[0].checkNumber} has been voided, no further action can be performed on this bond check` }>
                                <Button variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: "white", minWidth: '5vw', maxHeight: '6vh', marginRight: '2rem', marginLeft: '1rem' }}
                                    disabled={row?.transactions[0]?.isVoid}
                                    onClick={() => printCheck(id)}

                                >
                                    Print
                                </Button>
                                <Button variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: "white", minWidth: '5vw', maxHeight: '6vh' }}
                                    onClick={() => voidBondCheck(id, row.transactions, row.checkAmount, row.transactions[0].sourceId)}
                                    disabled={row.disburseDate != null | row?.transactions[0]?.isVoid}

                                >
                                    VOID
                                </Button>
                            </Tooltip>
                        </>
                        :
                        <>
                            <Button variant="contained"
                                sx={{ backgroundColor: "steelblue", color: "white", minWidth: '5vw', maxHeight: '6vh', marginRight: '2rem', marginLeft: '1rem' }}
                                onClick={() => handleSaveClick(id)}
                            >
                                Save
                            </Button>
                            <Button variant="contained"
                                sx={{ backgroundColor: "steelblue", color: "white", minWidth: '5vw', maxHeight: '6vh' }}
                                onClick={() => handleCancelClick(id)}
                            >
                                Cancel
                            </Button>
                        </>
                )
            }
        }
    ];


    return (
        <>
            <Box sx={{ width: "70%", margin: "1vh auto", gap: "2rem", display: "flex", flexDirection: "row", justifyContent: 'space-evenly' }}>
                <Box mt={1.5} style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <h5>Bond Number: {selectedBond.bondNumber}</h5>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                        </div>
                    </div>
                </Box>
            </Box>
            <Divider sx={{ borderColor: 'lightgray', borderWidth: '1px', margin: '2vh 2vw', width: '95%' }} component="hr" />
            <Box sx={{ display: 'flex', margin: '2vh auto', justifyContent: 'center', flexDirection: "column", width: '100%' }}>
                <Box sx={{ height: '50vh', width: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem auto', marginBottom: '1.5vh' }}>

                    <DataGridPremium
                        columns={columns}
                        rows={checkInfo}
                        loading={loadingPrint}
                        editMode="row"
                        rowModesModel={refundRowModesModel}
                        onRowModesModelChange={handleRefundRowModesModelChange}
                        onRowEditStop={handleRefundRowEditStop}
                        processRowUpdate={processRefundRowUpdate}
                        onProcessRowUpdateError={handleProcessRefundRowUpdateError}
                        slots={{ loadingOverlay: LinearProgress }}
                        rowHeight={60}
                        localeText={{ noRowsLabel: "No refunds attached to this Bond" }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 25 } }
                        }}
                    />
                </Box>
                <Box sx={{ marginLeft: "auto", marginBottom: '1vh', marginRight: '.5vw' }}>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
        </>
    )
}

export default BondRefundChecksModal;