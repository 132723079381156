import React, { useContext, useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Box, Tab, Tabs, Paper } from '@mui/material';
import { toast } from 'react-toastify';

import GenInfo from '../pages/GenInfo';
import FilesView from '../pages/tabs/Files';
import CitationView from '../pages/tabs/Citation';
import PaymentsView from '../pages/tabs/Payments';
import PersonInfoView from '../pages/tabs/PersonInfo';
import ViolationsView from '../pages/tabs/ViolationsView';
import VehicleAccidentView from '../pages/tabs/VehicleAccident';
import BondsView from '../pages/tabs/BondsView';
//import Restitution from '../pages/tabs/Restitution';

import { CaseContext } from '../context/CaseContext';
import { SearchContext } from '../context/SearchContext';
import UserContext from '../context/UserContext';
import { ThemeModeContext, apiRoute } from '../App';

const CitationForm = (props) => {
    const {
        caseNumber,
        updateBalanceRows,
        updateCaseBalance,
        value,
        handleTabSelect,
        changes,
        setChanges,
        modalType,
        setModalType,
        handleChangesModal,
        changesModal,
        setChangesModal,
        originalState,
        setOriginalState,
        from,
        setFrom
    } = props;

    const {
        citationView,
        caseView,
        setCaseView,
        balance,
        netTotal,
        statuteRows,
        setStatuteRows,
        setChargesRows,
        violationRows,
        setViolationRows,
        setRestitutionRows,
        caseHistoryRows
    } = useContext(CaseContext);

    const { searchParams, refreshSearch } = useContext(SearchContext);

    const { allTabs, filesTab, entityState, entityZip } = useContext(UserContext);

    const { mode } = useContext(ThemeModeContext);

    const today = new Date();

    const navigate = useNavigate();

    const [violationModal, setViolationModal] = useState(false);
    const [violationModalType, setViolationModalType] = useState('');
    const [violationModalWidth, setViolationModalWidth] = useState('');
    const [openArchiveModal, setArchiveModal] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [openCreditCardPaymentsLock, setOpenCreditCardPaymentsLock] = useState(false);
    const [loadingCreditCard, setLoadingCreditCard] = useState(false);
    const [reason, setReason] = useState(caseView?.reason);
    const [vioRows, setVioRows] = useState(violationRows);
    //const [restitutionModal, setRestitutionModal] = useState(false);
    //const [restitutionId, setRestitutionId] = useState(statuteRows.filter(sr => sr.description === 'RESTITUTION')[0]?.statute ?? null);
    const [violationView, setViolationView] = useState({});
    const [violationData, setViolationData] = useState({});
    const initialBondState = {
        fullCaseNumber: caseView.fullCaseNumber || '',
        caseTypes: caseView.caseType || '',
        clerkCaseNum: caseView.clerkCaseNum || '',
        citNumber: citationView.citNumber || '',
        violationDate: caseView.violationDate || '',
        courtDatetime: citationView.courtDatetime === '9999-12-31' ? '' : (citationView.courtDatetime || ''),
        lastName: citationView.lastName || '',
        firstName: citationView.firstName || '',
        midInit: citationView.midInit || '',
        suffix: citationView.suffix || '',
        agency: citationView.issueAgency || '',
        court: citationView.court || '',
        division: citationView.divisionCode || '',
        caseStatus: citationView.caseStatus || '',
        address1: citationView.address1 || '',
        address2: citationView.address2 || '',
        zipCode: citationView.zipCode || '',
        state: citationView.state || entityState,
        city: citationView.city || '',
        dateOfBirth: citationView.dateOfBirth ? new Date(citationView.dateOfBirth).toISOString().substring(0, 10) : '',
        race: citationView.race || '',
        sex: citationView.sex || '',
        socSecNum: citationView.socSecNum === "Value could not " ? "" : citationView.socSecNum,
        height: citationView.height || '',
        weight: citationView.weight || '',
        homePhone: citationView.homePhone || '',
        workPhone: citationView.workPhone || '',
        mobilePhone: citationView.mobilePhone || '',
        driverLicNumber: citationView.driverLicNumber || '',
        driverLicClass: citationView.driverLicClass || '',
        driverLicState: citationView.driverLicState || '',
        commercialDl: citationView.commercialDl || '',
        pickedUpDl: citationView.pickedUpDl || '',
        vehicleYear: citationView.vehicleYear || '',
        vehicleMake: citationView.vehicleMake || '',
        vehicleModel: citationView.vehicleModel || '',
        vehicleType: citationView.vehicleType || '',
        vehicleStyle: citationView.vehicleStyle || '',
        vehicleColor: citationView.vehicleColor || '',
        vehicleLicenseNumber: citationView.vehicleLicenseNumber || '',
        vehicleLicenseState: citationView.vehicleLicenseState || '',
        vehicleLicenseYear: citationView.vehicleLicenseYear || '',
        hazmatVehicle: citationView.hazmatVehicle || '',
        commercialVehicle: citationView.commercialVehicle || '',
        propertyDamage: citationView.propertyDamage || '',
        personalInjury: citationView.personalInjury || '',
        fatal: citationView.fatal || '',
        itemNum: citationView.itemNum || '',
        stateControlNum: citationView.stateControlNum || '',
        declinedDwiTest: citationView.declinedDwiTest || '',
        dwiTestEqAgency: citationView.dwiTestEqAgency || '',
        dwiTestingAgency: citationView.dwiTestingAgency || '',
        badgeNumber: citationView.badgeNumber || '',
        badgeName: citationView.badgeName || '',
        speedOfVehicle: citationView.speedOfVehicle || '',
        speedLimit: citationView.speedLimit || '',
        arrested: citationView.arrested || '',
        arrestDatetime: citationView.arrestDatetime ? new Date(citationView.arrestDatetime).toISOString().substring(0, 10) : '',
        areaBlockOf: citationView.areaBlockOf || '',
        areaStreetNum: citationView.areaStreetNum || '',
        areaStreetDir: citationView.areaStreetDir || '',
        areaStreetName: citationView.areaStreetName || '',
        areaStreetType: citationView.areaStreetType || '',
        areaCity: citationView.areaCity || '',
        schoolZone: citationView.schoolZone || 'N',
        constructionZone: citationView.constructionZone || 'N',
        areaCrossDir: citationView.areaCrossDir || '',
        areaCrossName: citationView.areaCrossName || '',
        areaCrossType: citationView.areaCrossType || '',
        isActive: caseView?.isActive ?? true,
        archiveReason: caseView?.archiveReason ?? '',
        dateFiled: caseView?.dateFiled ?? '',
        isDeleted: caseView?.isDeleted,
        deletedReason: caseView?.deletedReason ?? '',
        arrestNumber: caseView?.arrestNumber ?? '',
        issuedChargeBack: caseView?.issuedChargeBack ?? false,
        isCreditCardPaymentsLocked: caseView?.isCreditCardPaymentsLocked ?? false
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                const updatedErrors = { ...state.errors };
                delete updatedErrors[action.field];
                return {
                    ...state,
                    errors: updatedErrors,
                };
            case 'RESET_STATE':
                return action.originalState;
            default:
                return state;
        }
    };

    const handleFieldChange = (field, value) => {
        if (state[field] !== value) setChanges(true);
        setModalType('saveChanges')
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'fullCaseNumber':
                if (!value && !state.fullCaseNumber) {
                    error = 'Full Case Number is required';
                }
                break;
            case 'citNumber':
                if (!value && !state.clerkCaseNum && !state.arrestNumber) {
                    error = 'Citation Number or Case Number is required';
                }
                break;
            case 'clerkCaseNum':
                if (!value && !state.citNumber && !state.arrestNumber) {
                    error = 'Citation Number or Case Number is required';
                }
                break;
            case 'arrestNumber':
                if (!value && !state.citNumber && !state.clerkCaseNum) {
                    error = 'Citation, Case or Arrest Number is required';
                }
                break;
            case 'violationDate':
                if (!value) {
                    error = 'Violation Date is required';
                } else if (new Date(value) > today) {
                    error = 'Violation Date cannot be a future date.';
                }
                break;
            case 'courtDatetime':
                if (new Date(value) < new Date(state.violationDate)) {
                    error = 'Court date cannot be before violation date.';
                }
                break;
            case 'lastName':
                if (!value) {
                    error = 'Last Name is required';
                }
                break;
            case 'firstName':
                if (!value) {
                    error = 'First Name is required';
                }
                break;
            case 'dateOfBirth':
                if (!value) {
                    error = 'Date of Birth is required';
                } else {
                    const currentDate = new Date();
                    const minDate = new Date(currentDate);
                    minDate.setFullYear(currentDate.getFullYear() - 120);
                    const maxDate = new Date(currentDate);
                    maxDate.setFullYear(currentDate.getFullYear() - 4);
                    if (new Date(value) < minDate || new Date(value) > maxDate) {
                        error = 'Invalid Date of Birth';
                    }
                }
                break;
            case 'socSecNum':
                if (!/^\d{9}$/.test(value)) {
                    error = 'Invalid Social Security Number';
                }
                break;
            case 'height':
                const val = value.split("'");
                const inches = val[1];
                const inch = inches.split('"')[0];
                if (value < 30 || value > 800) {
                    error = 'Height must be between 30 and 800';
                }
                if ((inch > 11)) {
                    error = 'Invalid Height'
                }
                break;
            case 'weight':
                if (value < 30 || value > 700) {
                    error = 'Weight must be between 30 and 700';
                }
                break;
            case 'homePhone':
            case 'workPhone':
            case 'mobilePhone':
                if (value.length >= 10 && value[0] === ('1' || '0')) {
                    error = 'Area Code cannot begin with 1 or 0'
                }
                if (value.length >= 10 && value[1] === ('9')) {
                    error = 'Area Code middle digit cannot be 9'
                }
                if (!/^\d{10,15}$/.test(value)) {
                    error = 'Phone number must be 10 to 15 digits';
                }
                break;
            case 'speedOfVehicle':
                if (value <= 0) {
                    error = 'Speed of Vehicle must be a positive number.';
                }
                break;
            case 'speedLimit':
                if (value <= 0) {
                    error = 'Speed Limit must be a positive number.';
                }
                break;
            default:
                break;
        }
        if (error != null) dispatch({ type: 'UPDATE_FIELD', field, value, error });
        // Dispatch CLEAR_FIELD_ERROR if error is null
        if (error == null) {
            if (state && state.errors) delete state.errors[field];
            dispatch({ type: 'CLEAR_FIELD_ERROR', field })
        }
    };

    const [state, dispatch] = useReducer(reducer, initialBondState);

    const resetState = () => {
        setChanges(false);
        setFrom([]);
        dispatch({ type: 'RESET_STATE', originalState });
        toast.success("Changes Discarded Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const updateCitation = async (e) => {
        setChanges(false);
        e.preventDefault();
        const citationData = {
            citNumber: state.citNumber,
            courtDatetime: state.courtDatetime || null,
            lastName: state.lastName,
            firstName: state.firstName,
            midInit: state.midInit,
            suffix: state.suffix,
            issueAgency: state.agency,
            court: state.court,
            divisionCode: state.division,
            caseStatus: state.caseStatus,
            address1: state.address1,
            address2: state.address2,
            zipCode: state.zipCode,
            state: state.state,
            city: state.city,
            dateOfBirth: state.dateOfBirth || null,
            race: state.race,
            sex: state.sex,
            socSecNum: state.socSecNum,
            height: state.height,
            weight: state.weight !== '' ? parseInt(state.weight, 10) : null,
            homePhone: state.homePhone,
            workPhone: state.workPhone,
            mobilePhone: state.mobilePhone,
            driverLicNumber: state.driverLicNumber,
            driverLicClass: state.driverLicClass,
            driverLicState: state.driverLicState,
            commercialDl: state.commercialDl,
            pickedUpDl: state.pickedUpDl,
            vehicleYear: state.vehicleYear,
            vehicleMake: state.vehicleMake,
            vehicleModel: state.vehicleModel,
            vehicleType: state.vehicleType,
            vehicleStyle: state.vehicleStyle,
            vehicleColor: state.vehicleColor,
            vehicleLicenseNumber: state.vehicleLicenseNumber,
            vehicleLicenseState: state.vehicleLicenseState,
            vehicleLicenseYear: state.vehicleLicenseYear,
            hazmatVehicle: state.hazmatVehicle,
            commercialVehicle: state.commercialVehicle,
            propertyDamage: state.propertyDamage,
            personalInjury: state.personalInjury,
            fatal: state.fatal,
            itemNum: state.itemNum,
            stateControlNum: state.stateControlNum,
            declinedDwiTest: state.declinedDwiTest,
            dwiTestEqAgency: state.dwiTestEqAgency,
            dwiTestingAgency: state.dwiTestingAgency,
            badgeNumber: state.badgeNumber,
            badgeName: state.badgeName,
            speedOfVehicle: state.speedOfVehicle !== '' ? parseInt(state.speedOfVehicle, 10) : null,
            speedLimit: state.speedLimit !== '' ? parseInt(state.speedLimit, 10) : null,
            arrested: state.arrested,
            arrestDatetime: state.arrestDatetime || null,
            areaBlockOf: state.areaBlockOf,
            areaStreetNum: state.areaStreetNum,
            areaStreetDir: state.areaStreetDir,
            areaStreetName: state.areaStreetName,
            areaStreetType: state.areaStreetType,
            areaCity: state.areaCity,
            schoolZone: state.schoolZone,
            constructionZone: state.constructionZone,
            areaCrossDir: state.areaCrossDir,
            areaCrossName: state.areaCrossName,
            areaCrossType: state.areaCrossType,
            dateFiled: state.dateFiled,
        };
        try {
            const res = await axios.patch(`${apiRoute}/api/citationtbls/${caseView.fkCitationIdName}`, citationData, {
                params: {
                    violationDate: state.violationDate,
                    caseNumber: state.clerkCaseNum,
                    caseType: state.caseTypes,
                    fullCaseNumber: state.fullCaseNumber,
                    dateFiled: state.dateFiled,
                    arrestNumber: state.arrestNumber
                },
            });
            toast.success(`General info saved`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            refreshSearch(searchParams)
            setChangesModal(false);
            if (from[0] === 'nav') {
                navigate(from[1])
            }
            if (violationRows?.length > 0) violationRows?.forEach(row => row.dueDate = res?.data?.courtDatetime?.split('T')[0])
        } catch (err) {
            console.log('error updating citation', err);
            if (err?.response?.data?.errors) {
                toast.error(`${err.response.data.errors}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(`${err?.response?.data.errors} error while trying to save general info`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    const handleArchiveCaseEvent = async (isActive, archiveReason) => {
        try {

            const path = !isActive
                ? `${apiRoute}/api/CaseTbls/archive`
                : `${apiRoute}/api/CaseTbls/restore`;

            const { data } = await axios.patch(path, { Id: caseNumber, IsActive: isActive, ArchiveReason: archiveReason });
            handleFieldChange('isActive', data.isActive);
            handleFieldChange('archiveReason', data.archiveReason);
            setArchiveModal(false);
            setChanges(false);
            toast.success(`${data.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        catch (e) {
            toast.error(`Error handling archive event for case, please reach out to support.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleDeleteCaseEvent = async (isDeleted, deleteReason) => {
        try {
            const path = !isDeleted
                ? `${apiRoute}/api/CaseTbls/recover`
                : `${apiRoute}/api/CaseTbls/delete`;

            const { data } = await axios.patch(path, { Id: caseNumber, IsDeleted: isDeleted, DeleteReason: deleteReason });
            handleFieldChange('isDeleted', data.isDeleted);
            handleFieldChange('deletedReason', data.archiveReason);
            setDeleteModal(false);
            setChanges(false);
            toast.success(`${data.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        catch (e) {
            console.log('e: ', e)
            toast.error(`Error handling archive event for case, please reach out to support.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleLockCreditCardPayments = (reason) => {
        if (reason !== "") {
            const isLocked = state.isCreditCardPaymentsLocked

            const path = isLocked
                ? `${apiRoute}/api/caseTbls/unlockCreditCardPayments/${caseNumber}`
                : `${apiRoute}/api/caseTbls/lockCreditCardPayments/${caseNumber}`;
            setLoadingCreditCard(true);
            axios.post(path, JSON.stringify(reason),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    toast.success(isLocked ? 'Credit Card Payments unlocked successfully!' : 'Credit Card Payments locked successfully!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                    setCaseView(prevCaseView => ({
                        ...prevCaseView,
                        isCaseCreditCardPaymentsLocked: !isLocked,
                    }));

                    handleFieldChange('isCreditCardPaymentsLocked', !isLocked);
                    setReason('');

                })
                .catch((err) => {
                    toast.error(`Error ${err.response.status} while changing payment flag.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                })
                .finally(() => {
                    setChanges(false);
                    setLoadingCreditCard(false);
                    setOpenCreditCardPaymentsLock(false);
                }
                );
        } else {
            toast.error(`Please provide reason.`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    };

    const getStatues = () => {
        //setLoading(true);
        axios.get(`${apiRoute}/api/statutefinetbls/date/${state?.violationDate}`)
            .then((res) => {
                const concatenatedData = res.data.map(subArray => subArray).flat();
                const statuteRows = concatenatedData.map(item => ({
                    ...item,
                    id: item.pkStatuteFineId,
                    chargeType: item.chargeType,
                    rsNumber: item.rsNumber,
                }));
                //const statuteRowsWithChargeType = statuteRows.filter(item => item.chargeType !== null);
                setStatuteRows(statuteRows);
            })
            .catch((err) => {
                console.error("GET STATUTES ERR");
            })
            .finally(() => { })//setLoading(false) })
    };

    useEffect(() => { getStatues(); }, [caseNumber]);

    useEffect(() => {
        setVioRows((prevViolationRows) => {
            const newRows = [...violationRows];
            return newRows.sort((a, b) => {
                const isCaseLevelFeeA = a?.caseLevelFee;
                const isCaseLevelFeeB = b?.caseLevelFee;


                if (isCaseLevelFeeA && !isCaseLevelFeeB) return 1
                if (!isCaseLevelFeeA && isCaseLevelFeeB) return -1;

                if (isCaseLevelFeeA && isCaseLevelFeeB) {
                    return a?.chargeType > b?.chargeType ? 1 : a?.chargeType < b?.chargeType ? -1 : 0;
                }

                return 0;
            });
        });

        //setRestitutionId(statuteRows.filter(sr => sr.description.toUpperCase() === 'RESTITUTION')[0]?.pkStatuteFineId);
    }, [statuteRows, setViolationRows, violationRows]);

    useEffect(() => {
        const filteredVioRows = vioRows?.filter(row => row.remainingBalance.toFixed(2) > 0.00 && (row.amendedDate === "" || row.amendedDate == null));
        setChargesRows(filteredVioRows);

        //const restitution = filteredVioRows?.filter(vr => vr.description === 'RESTITUTION')[0]?.pkViolationId;
        //setRestitutionRows(caseHistoryRows.filter(df => df.fkViolationId === restitution))
    }, [vioRows]);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    }

    const tabs = allTabs ? [
        {
            label: 'Person Information',
            tabContent:
                <PersonInfoView
                    handleFieldChange={handleFieldChange}
                    handleBlur={handleBlur}
                    state={state}
                    ts={textFieldStyle}
                    resetState={resetState}
                />
        },
        {
            label: 'Citation',
            tabContent:
                <CitationView
                    handleFieldChange={handleFieldChange}
                    handleBlur={handleBlur}
                    state={state}
                    ts={textFieldStyle}
                />
        },
        {
            label: 'Vehicle/Accident',
            tabContent:
                <VehicleAccidentView
                    handleFieldChange={handleFieldChange}
                    handleBlur={handleBlur}
                    state={state}
                    ts={textFieldStyle}
                    caseNumber={caseNumber}
                />
        },
        {
            label: 'Violations',
            tabContent:
                <ViolationsView
                    balance={balance}
                    netTotal={netTotal}
                    updateBalanceRows={updateBalanceRows}
                    updateCaseBalance={updateCaseBalance}
                    state={state}
                    ts={textFieldStyle}
                    handleFieldChange={handleFieldChange}
                    violationModal={violationModal}
                    setViolationModal={setViolationModal}
                    violationModalType={violationModalType}
                    setViolationModalType={setViolationModalType}
                    violationModalWidth={violationModalWidth}
                    setViolationModalWidth={setViolationModalWidth}
                    statuteRows={statuteRows}
                    violationRows={vioRows}
                    setViolationRows={setVioRows}
                    violationView={violationView}
                    setViolationView={setViolationView}
                    violationData={violationData}
                    setViolationData={setViolationData}
                />
        },
        {
            label: 'Payments',
            tabContent: <PaymentsView updateCaseBalance={updateCaseBalance} ts={textFieldStyle} state={state} caseNumber={caseNumber} statuteRows={statuteRows} violationRows={vioRows} setViolationRows={setVioRows} />
        },
        {
            label: 'Bonds',
            tabContent: <BondsView updateCaseBalance={updateCaseBalance} ts={textFieldStyle} state={state} />
        },
        //{
        //    label: 'Restitution',
        //    tabContent:
        //        <Restitution
        //            updateCaseBalance={updateCaseBalance}
        //            ts={textFieldStyle}
        //            restitutionModal={restitutionModal}
        //            setRestitutionModal={setRestitutionModal}
        //            caseNumber={caseNumber}
        //            citationNumber={caseView.fkCitationIdName}
        //            restitutionStatuteId={restitutionId}
        //            caseType={caseView.caseType}
        //            citationView={citationView}
        //            caseView={caseView}
        //            violationRows={vioRows}
        //            statuteRows={statuteRows}
        //            setViolationRows={setVioRows}
        //            violationView={violationView}
        //            setViolationView={setViolationView}
        //            violationData={setViolationData}
        //            updateBalanceRows={updateBalanceRows}
        //            setViolationData={setViolationData}
        //        />
        //},
        ...(filesTab
            ? [
                {
                    label: 'Files',
                    tabContent: <FilesView />
                }
            ]
            : [])
    ] : [
        {
            label: 'Violations',
            tabContent:
                <ViolationsView
                    balance={balance}
                    netTotal={netTotal}
                    updateBalanceRows={updateBalanceRows}
                    updateCaseBalance={updateCaseBalance}
                />
        },
        {
            label: 'Payments',
            tabContent: <PaymentsView updateCaseBalance={updateCaseBalance} />
        },
    ];

    useEffect(() => {
        setOriginalState(initialBondState)
    }, []);

    return (
        <>
            <GenInfo
                caseNumber={caseNumber}
                updateBalanceRows={updateBalanceRows}
                updateCaseBalance={updateCaseBalance}
                handleFieldChange={handleFieldChange}
                updateCitation={updateCitation}
                handleBlur={handleBlur}
                state={state}
                ts={textFieldStyle}
                searchParams={searchParams}
                refreshSearch={refreshSearch}
                changes={changes}
                setChanges={setChanges}
                modalType={modalType}
                setModalType={setModalType}
                handleChangesModal={handleChangesModal}
                changesModal={changesModal}
                setChangesModal={setChangesModal}
                originalState={originalState}
                setOriginalState={setOriginalState}
                resetState={resetState}
                from={from}
                setFrom={setFrom}
                violationModal={violationModal}
                setViolationModal={setViolationModal}
                violationModalType={violationModalType}
                setViolationModalType={setViolationModalType}
                violationModalWidth={violationModalWidth}
                setViolationModalWidth={setViolationModalWidth}
                handleArchiveCaseEvent={handleArchiveCaseEvent}
                openArchiveModal={openArchiveModal}
                setArchiveModal={setArchiveModal}
                openDeleteModal={openDeleteModal}
                setDeleteModal={setDeleteModal}
                handleDeleteCaseEvent={handleDeleteCaseEvent}
                handleLockCreditCardPayments={handleLockCreditCardPayments}
                openCreditCardPaymentsLock={openCreditCardPaymentsLock}
                setOpenCreditCardPaymentsLock={setOpenCreditCardPaymentsLock}
                loadingCreditCard={loadingCreditCard}
                setLoadingCreditCard={setLoadingCreditCard}
                reason={reason}
                setReason={setReason}
            />
            <Paper sx={{ width: "100%", mt: "1vh", pb: '1vh' }} elevation={10}>
                <Tabs
                    value={value}
                    onChange={handleTabSelect}
                    centered
                >
                    {tabs?.map(({ label }) => (
                        <Tab label={label} key={label} sx={{ fontWeight: 'bold' }} />
                    ))}
                </Tabs>
                <Box>{tabs[value]?.tabContent}</Box>
            </Paper>
        </>
    )
};

export default CitationForm;