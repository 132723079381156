import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';


import { Box, Button, MenuItem, TextField, Typography, Paper } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeModeContext, apiRoute } from '../../../App';
import { formatDateCST, formatDateTimeCST, formattedDate, formatTime, formatTimeCST } from '../../../Utils';

const ReportsModal = ({ handleClose, reportPath }) => {

    const { mode } = useContext(ThemeModeContext);

    const [disburseDates, setDisburseDates] = useState([]);
    const [loadingDates, setLoadingDates] = useState(true);
    const [selectedDate, setSelectedDate] = useState(false);
    const [previousDate, setPreviousDate] = useState('');
    const [payeeName, setPayeeName] = useState('');
    const [payeeList, setPayeeList] = useState([]);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === 'dark' ? '#4C4E52' : 'white',
            color: mode === 'dark' ? 'white' : 'black',
        },
        '& .MuiInputLabel-root': {
            color: mode === 'dark' ? 'white' : 'black',
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const columns = [
        {
            field: "Date",
            headerName: "Disburse Date",
            width: 175
        },
        {
            field: "Time",
            headerName: "Disburse Time",
            width: 175
        },
        {
            field: "EnteredBy",
            headerName: "Disbursed By",
            width: 250
        }
    ];

    const getDisburseReconDates = () => {
        setLoadingDates(true);
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formatDateCST(row.disburseDate),
                        Time: formatTimeCST(row.disburseDate),
                        FullDate: row.disburseDate,
                        EnteredBy: row.enteredBy,
                        Value: row.masterDisburseId
                    }
                })
                setDisburseDates(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
            })
            .finally(() => setLoadingDates(false))
    };

    const getDisbursePayees = () => {
        axios.get(`${apiRoute}/api/PayeeTbls/disburse/distinctPayees`)
            .then(({ data }) => {
                setPayeeList(data);
            })
            .catch((err) => {
                console.error("error getting disburse payees", err);
            })
    }

    const handleGenerateReportClick = () => {
        if (selectedDate) {
            setLoadingDates(true);

            const getReportRoute = reportPath.includes("Recon") ? `${apiRoute}/${reportPath}?disburseDate=${selectedDate.row.FullDate}&previousDate=${previousDate}&disburseId=${selectedDate.row.Value}` :
                reportPath.includes("Distribution") ? `${apiRoute}/${reportPath}?disburseId=${selectedDate.row.Value}&payeeName=${payeeName}` :
                    `${apiRoute}/${reportPath}?disburseId=${selectedDate.row.Value}&disburseDate=${selectedDate.row.FullDate}`;

            axios.get(getReportRoute, { responseType: 'arraybuffer' })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    handleClose();
                    window.open(dataUrl, '_blank');
                })
                .catch(error => {
                    toast.error(`Error ${error.response.status} fetching report`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    console.error('Error fetching report from front end:', error);
                })
                .finally(() => setLoadingDates(false))
        } else {
            toast.info('Please select a date', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    useEffect(() => {
        getDisburseReconDates();
        if (reportPath.includes("Distribution")) {
            getDisbursePayees();
        }
    }, []);

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Report Dates</h1>
            </Paper>
            <Box p={1} sx={{ }}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Select a date from the list below.
                </Typography>
                <hr />
                <Box sx={{ height: "45vh", display: 'flex' }}>
                    <DataGridPremium
                        columns={columns}
                        rows={disburseDates}
                        loading={loadingDates}
                        onCellDoubleClick={handleGenerateReportClick}
                        autoHeight={false}
                        onRowClick={(currentRow) => {
                            setSelectedDate(currentRow);
                            const currentIndex = disburseDates.findIndex((row) => row.id === currentRow.id);
                            setPreviousDate(currentIndex === disburseDates.length - 1 ? '' : disburseDates[currentIndex + 1]?.FullDate)
                        }}
                        localeText={{ noRowsLabel: "No Disbursement Dates Found" }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 25 } }
                        }}
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                "marginTop": "1em",
                                "marginBottom": "1em"
                            },

                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                                outline: "none !important",
                            },
                        }}
                    />
                </Box>
               { reportPath.includes("Distribution") && <TextField
                    sx={{ ...textFieldStyle, width: '25vw', mt: '1.6vh', textAlign: "center", ml: '4.3vw' }}
                    select
                    value={payeeName}
                    onChange={(e) => setPayeeName(e.target.value)}
                    label="Filter By Payee"
                    //InputLabelProps={{ shrink: true }}
                >
                    <MenuItem key={"NONE"} value={""}
                    >
                    NONE
                    </MenuItem>
                    {
                        payeeList.map(payee => {
                            return (
                                <MenuItem key={payee.pkPayeeId} value={payee.paytoName}
                                >
                                    {payee.paytoName}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>}
                <hr />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: '1rem', mt: '1vh' }}>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleGenerateReportClick} disabled={loadingDates}>Generate Report</Button>
                    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose} disabled={loadingDates}>Cancel</Button>
                </Box>

            </Box>
        </Paper>
    )
}

export default ReportsModal;