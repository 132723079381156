import React, { useState, useCallback, useContext, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Box, Button, TextField, Paper, MenuItem, Modal, Divider } from '@mui/material';
import { GridRowModes, DataGridPremium, GridActionsCellItem, GridRowEditStartReasons, GridRowEditStopReasons } from '@mui/x-data-grid-premium';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { RiDeleteBinLine } from "react-icons/ri";

import { apiRoute } from '../../../../App';
import { DistributionCheckContext } from '../../../../context/DistributionCheckContext';
import { CaseContext } from '../../../../context/CaseContext';
import DisburseBondTransferModal from './DisburseBondTransferModal';


const BondTransferModal = (props) => {
    //start variables and params
    const {
        handleClose,
        selectedBond,
        setSelectedBond,
        citationView,
        setBondRows
    } = props


    const { payees } = useContext(DistributionCheckContext);
    const { caseView } = useContext(CaseContext);

    const [selectedPayee, setSelectedPayee] = useState("");
    const [transferAmount, setTransferAmount] = useState("$0.00");
    const [existingTransfers, setExistingTransfers] = useState([]);
    const [transferRowModesModel, setTransferRowModesModel] = useState({});
    const [isDisburseModalOpen, setIsDisburseModalOpen] = useState(false);
    const [selectedTransferId, setSelectedTransferId] = useState({});
    const [disburseStep, setDisburseStep] = useState("DISBURSE");

    const isDisabled = selectedBond.bondAmount === 0 || (selectedPayee === "" || transferAmount.replace(/[^0-9.]/g, '') < "0.01" || transferAmount.replace(/[^0-9.]/g, '') > selectedBond.remainingBondAmount);


    // DataGrid related items
    const handleTransferRowModesModelChange = (newRowModesModel) => {
        setTransferRowModesModel(newRowModesModel);
    };

    const handleTransferRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => {
        setTransferRowModesModel({ ...transferRowModesModel, [id]: { mode: GridRowModes.Edit } })
    };

    const handleSaveClick = (id) => {
        setTransferRowModesModel({ ...transferRowModesModel, [id]: { mode: GridRowModes.View } })
    };

    const handleCancelClick = (id) => {
        setTransferRowModesModel({
            ...transferRowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true }
        });
    };

    const handleDeleteClick = async (id) => {
        const currentBondId = selectedBond.id;
        try {
            const response = await axios.put(`${apiRoute}/api/bond/transfer/delete`, { AllocationId: id });

            setExistingTransfers(existingTransfers.filter((transfer) => transfer.id !== id));
            const newRows = response.data.map((bond) => ({
                ...bond,
                remainingBondAmount: bond.cashBondTransactions.reduce((remaining, transaction) => {
                    if (transaction.isActive) {
                        return remaining - transaction.transactionAmount
                    }
                    return remaining
                }, bond.bondAmount)
            }))
            setBondRows(newRows);
            setSelectedBond(newRows.find(bond => bond.id === currentBondId));
        } catch (error) {
            console.log("error deleting bond transfer", error);
            toast.error(`${error.response?.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    const processTransferRowUpdate = useCallback(
        async (updatedTransfer, originalTransfer) => {
            const currentBondId = selectedBond.id;

            const updateTransferData = {
                AllocationId: originalTransfer.id,
                TransferAmount: updatedTransfer.amount,
                ReceivingPayeeId: updatedTransfer.target
            }
            try {
                const response = await axios.put(`${apiRoute}/api/bond/transfer`, updateTransferData)

                const bondRows = response.data.bondRows.map((bond) => ({
                    ...bond,
                    remainingBondAmount: bond.cashBondTransactions.reduce((remaining, transaction) => {
                        if (transaction.isActive) {
                            return remaining - transaction.transactionAmount
                        }
                        return remaining
                    }, bond.bondAmount)
                }))
                setBondRows(bondRows);
                setSelectedBond(bondRows.find(bond => bond.id === currentBondId));
                return response.data.transferRecord;

            } catch (error) {
                throw new Error(`Error while saving transfer: ${error.response?.data || error.message}`)
            }
        }, [])


    const handleProcessTransferRowUpdateError = useCallback((error) => {
        toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    })

    const columns = [
        {
            field: 'target',
            headerName: 'Payee',
            width: 300,
            editable: true,
            type: 'singleSelect',
            valueOptions: payees.map((payee) => ({ value: payee.pkPayeeId, label: payee.paytoName })),
            renderCell: (params) => {
                return (<>{payees.find((payee) => { return payee.pkPayeeId === params.value }).paytoName}</>)
            }
        },
        {
            field: 'enteredBy',
            headerName: 'Transferred By',
            width: 250
        },
        {
            field: 'amount',
            headerName: 'Transfer Amount',
            width: 250,
            align: 'right',
            headerAlign: 'right',
            editable: true,
            renderCell: (params) => {
                return (<>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>)
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                const isInEditMode = transferRowModesModel[id]?.mode === GridRowModes.Edit;

                return isInEditMode ?
                    [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={() => handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={() => handleCancelClick(id)}
                            color="inherit"
                        />
                    ]
                    :
                    [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleEditClick(id)}
                            color="inherit"
                            disabled={row.disburseDate !== null}
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleDeleteClick(id)}
                            color="inherit"
                            disabled={row.disburseDate !== null}
                        />,
                    ]
            }
        },
        {
            field: 'disburse',
            headerName: 'Disburse',
            width: 200,
            renderCell: (params) => {
                return (
                    <Button variant="contained"
                        sx={{ backgroundColor: "steelblue", color: "white", minWidth: '7vw', maxHeight: '6vh' }}
                        onClick={() => { setSelectedTransferId(params.id); setIsDisburseModalOpen(true); }}
                    disabled={params.row.disburseDate != null}
                    >
                        Disburse
                    </Button>
                )
            }
        }
    ]

    // component methods
    const handleTransfer = () => {
        const currentBondId = selectedBond.id
        const payee = {
            VendorType: "BOND TRANSFER",
            PaytoName: `${citationView.lastName}, ${citationView.firstName}`,
            PaytoAddress1: citationView.address1,
            PaytoAddress2: citationView.address2,
            PaytoCity: citationView.city,
            PaytoState: citationView.state,
            PaytoZip: citationView.zipCode,
            FkSourceId: caseView.pkCaseId,
            SourceId: selectedBond.id,
            TransferAmount: parseFloat(transferAmount.slice(1)),
            ReceivingPayeeId: selectedPayee
        }

        axios.post(`${apiRoute}/api/bond/Transfer`, payee)
            .then(({ data }) => {
                const newBondRows = data.bonds.map((bond) => ({
                    ...bond,
                    remainingBondAmount: bond.cashBondTransactions.reduce((remaining, transaction) => {
                        if (transaction.isActive) {
                            return remaining - transaction.transactionAmount
                        }
                        return remaining
                    }, bond.bondAmount)

                }))
                setBondRows(newBondRows)
                setSelectedBond(newBondRows.find((bond) => bond.id === currentBondId))
                setTransferAmount("$0.00");
                setExistingTransfers(data.transfers);
            })
            .catch((err) => {
                console.log(err);
            })

    }

    const handleDisburseModalClose = () => setIsDisburseModalOpen(false);

    // useEffect
    useEffect(() => {

        axios.get(`${apiRoute}/api/bond/Transfers?bondId=${selectedBond.id}`)
            .then(({ data }) => {
                setExistingTransfers(data);
            })
            .catch((err) => {
                console.log(err);
            })


    }, [selectedBond]);

    return (<>
        <Box sx={{ width: "70%", margin: "1vh auto", gap: "2rem", display: "flex", flexDirection: "row", justifyContent: 'space-evenly' }}>
            <Box mt={1.5} style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <h5>Bond Number: {selectedBond.bondNumber}</h5>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                    </div>
                </div>
            </Box>
        </Box>
        <Divider sx={{ borderColor: 'lightgray', borderWidth: '1px', margin: '2vh 2vw', width: '95%' }} component="hr" />
        <Box sx={{ display: 'flex', margin: '2vh auto', justifyContent: 'center', flexDirection: "column", width: '100%' }}>
            <Box sx={{ display: 'flex', alignSelf: 'center', gap: '1rem', width: '85%' }}>
                <TextField
                    label={"Remaining Bond Amount"}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                    sx={{ minWidth: '15vw', justifyContent: 'end' }}
                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.remainingBondAmount)}
                />
                <TextField
                    label={"Payee"}
                    select
                    fullWidth
                    onChange={(e) => setSelectedPayee(e.target.value)}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: '20em',
                                },
                            },
                        },
                    }}
                >
                    {payees.map((payee) => {
                        return <MenuItem value={payee.pkPayeeId}>{payee.paytoName}</MenuItem>
                    })}
                </TextField>
                <TextField
                    label={"Transfer Amount"}
                    value={transferAmount}
                    sx={{ minWidth: '10vw' }}
                    inputProps={{ style: { textAlign: 'right' } }}
                    onFocus={() => setTransferAmount('')}
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, '');
                        if (!isNaN(value)) {
                            setTransferAmount(value);
                        }
                    }}
                    onBlur={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, '');
                        if (!isNaN(value)) {
                            setTransferAmount(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value));
                        }
                    }}
                />
                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", minWidth: '7vw', maxHeight: '6vh' }} onClick={handleTransfer}
                    disabled={isDisabled} >Transfer</Button>
            </Box>
            <Box sx={{ height: '50vh', width: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem auto', marginBottom: '1.5vh' }}>
                <DataGridPremium
                    columns={columns}
                    rows={existingTransfers}
                    editMode="row"
                    rowModesModel={transferRowModesModel}
                    onRowModesModelChange={handleTransferRowModesModelChange}
                    onRowEditStop={handleTransferRowEditStop}
                    processRowUpdate={processTransferRowUpdate}
                    onProcessRowUpdateError={handleProcessTransferRowUpdateError}
                    rowHeight={60}
                    isCellEditable={(params) => params.row.disburseDate === null}
                    localeText={{ noRowsLabel: "Create New Transfer" }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } }
                    }}
                />
            </Box>
            <Box sx={{ marginLeft: "auto", marginBottom: '1vh', marginRight: '.5vw' }}>
                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Cancel</Button>
            </Box>
        </Box>
        <Modal
            open={isDisburseModalOpen}
            onClose={handleDisburseModalClose}
            sx={{ width: '25%', m: '5vh auto' }}
        >
            <DisburseBondTransferModal
                handleDisburseModalClose={handleDisburseModalClose}
                selectedTransferId={selectedTransferId}
                disburseStep={disburseStep}
                setDisburseStep={setDisburseStep}
                existingTransfers={existingTransfers}
                setExistingTransfers={setExistingTransfers}
            />
        </Modal>
    </>);
}


export default BondTransferModal;