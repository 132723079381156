import React, { useContext, useState } from "react";

import { Box, Button, InputAdornment, TextField, Paper } from "@mui/material";

import { UserContext } from '../../../context/UserContext';

const CommentsBox = (props) => {
    const {
        updateCaseComments,
        textFieldStyle,
        comments,
        errors,
        handleClose,
    } = props;

    const { userName } = useContext(UserContext);
    // temp variable to hold initial comments state
    // only submitting 'r' and setting the state of it to allow for 
    // cancelling the component and keep initial state
    const [c, setC] = useState(comments);
    const [additionalComments, setAdditionalComments] = useState("");
    const [addingComment, setAddingComment] = useState(false);

    const mergeCommentsAndUpdate = (e, c) => {
        let updatedComments;
        if (c.length < 1) {
            updatedComments = `<${userName} ${new Date().toDateString()}> ` + additionalComments;
        } else {
            updatedComments = c + `\n<${userName} ${new Date().toDateString()}> ` + additionalComments;
        }


        updateCaseComments(e, updatedComments);
    }

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: "steelblue", color: "white", mb: "2vh", p: ".5vh", textAlign: 'center', height: 'auto', display: 'flex' }}>
                <h4 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Case Comments</h4>
            </Paper>
            <Box sx={{ width: "97%", m: "2vh auto" }}>
                <form onSubmit={(e) => mergeCommentsAndUpdate(e, c)}>
                    <Box>
                        <TextField
                            sx={textFieldStyle}
                            multiline
                            rows={11}
                            value={c}
                            onChange={(e) => setC(e.target.value)}
                            fullWidth
                            label="Comments"
                            variant="outlined"
                            error={Boolean(errors.comments)}
                            helperText={errors.comments?.message}
                            InputProps={{ readOnly: true }}
                        />
                        {addingComment &&
                            <TextField
                            sx={{ ...textFieldStyle, mt: '2vh' }}
                            multiline
                            rows={4}
                            value={additionalComments}
                            onChange={(e) => setAdditionalComments(e.target.value)}
                            fullWidth
                            label="Additional Comments"
                            variant="outlined"
                            error={Boolean(errors.comments)}
                            helperText={errors.comments?.message}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{`<${userName} ${new Date().toDateString()}> `}</InputAdornment>
                            }}
                            />
                        }
                    </Box>
                    {/*<Box>*/}
                    {/*    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mt: '1vh' }}>Case Comments</Button>*/}
                    {/*</Box>*/}
                    {/*Sean requested this button be hidden until further notice.*/}
                    {/*<Box>*/}
                    {/*</Box>*/}
                    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "right", pb: '1vh' }}>
                        {!addingComment &&
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mt: '1vh' }} onClick={() => setAddingComment(true)}>Add Comments</Button>
                        }

                        {addingComment &&
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", mt: "1vh" }} disabled={ additionalComments?.replace(/\s/g, '').length < 1} type="submit">
                                Save Comments
                            </Button>
                        }
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", mt: "1vh", ml: "4px" }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper>
    );
};

export default CommentsBox;
