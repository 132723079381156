import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Box, LinearProgress, Icon, Button, TextField, InputAdornment } from '@mui/material';

import Mail from '@mui/icons-material/Mail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import Logo from "../../images/iss.png";
import BG from "../../images/LoginPng.png";

import { ThemeModeContext, apiRoute } from '../../App';

const ForgotPassword = () => {
    const { mode } = useContext(ThemeModeContext);

    const [loadingEmail, setLoadingEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(1);
    const [tempCode, setTempCode] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [error, setError] = useState('');
    const [errorCheck, setErrorCheck] = useState(false);

    const navigate = useNavigate();

    function emailCheck(email) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !emailRegex.test(email);
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoadingEmail(true);
        try {
            const res = await axios.post(`${apiRoute}/api/email/${email}`);
            const resetToken = localStorage.getItem('passwordToken') !== null;
            if (resetToken) {
                localStorage.removeItem('passwordToken');
                localStorage.setItem('passwordToken', res.data.token);
            } else {
                localStorage.setItem('passwordToken', res.data.token);
            }
            setTempCode(res.data.code);
        } catch (err) {
            console.log('error sending email: ', err);
        } finally {
            setLoadingEmail(false);
            toast.info(`Reset password email sent to ${email} if email is valid.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setStep(2);
        }
    };

    const handleCodeCheck = (e) => {
        e.preventDefault();
        if (codeInput !== tempCode) {
            setErrorCheck(true);
            setError('Incorrect code');
        }
        else {
            const token = localStorage.getItem('passwordToken');
            setErrorCheck(false);
            navigate(`/resetpassword/${token}`);
        }
    }

    return (
        // bgContainer
        (<Box sx={{
            backgroundImage: `url(${BG})`,
            backgroundSize: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% -1vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            {/* loginContainer */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center',
                backgroundSize: 'contain'
            }}>
                {/* loginBox */}
                <Box sx={{
                    position: 'relative',
                    zIndex: '0',
                    borderRadius: '20px',
                    filter: 'drop-shadow(0px 1px 0.5px black)',
                    minHeight: '38vh',
                    minWidth: '50vh',
                    backgroundColor: mode === 'dark' ? 'darkgrey' : 'white'
                }}>
                    {/* logoBox */}
                    <Box sx={{
                        width: '45vh',
                        margin: '0 auto',
                        position: 'relative',
                        zIndex: '1',
                        textAlign: 'center',
                        bottom: '40px',
                        borderRadius: '10px',
                        filter: 'drop-shadow(0px 1px .5px gray)',
                        backgroundColor: mode === 'dark' ? 'grey' : 'black'
                    }}>
                        <img
                            src={Logo}
                            alt="Interactive Software Solutions Logo"
                            width='115rem'
                        />
                    </Box>
                    {
                        step === 1 ? (
                            <form onSubmit={sendEmail}>
                                <Box px={10} sx={{
                                    textAlign: 'center',
                                    backgroundColor: mode === 'dark' ? 'darkgrey' : 'white'
                                }}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Box mb={5}>
                                            <TextField
                                                fullWidth
                                                id='email'
                                                label='Email'
                                                type='email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                margin="dense"
                                                variant="standard"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <Icon edge='end'>
                                                                <Mail />
                                                            </Icon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        padding: '4vh 0',
                                        filter: 'drop-shadow(0px 1px .5px gray)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <Box sx={{ width: '100%' }}>
                                            <Button type='submit' variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }} disabled={emailCheck(email)}>
                                                <div>
                                                    {
                                                        loadingEmail ? (
                                                            <>
                                                                Sending Email...
                                                            </>
                                                        ) : (
                                                            <>
                                                                Send Email
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </Button>
                                            {
                                                loadingEmail && (
                                                    <LinearProgress color="error" sx={{ width: '99%', m: '0 auto' }} />
                                                )
                                            }
                                        </Box>
                                    </Box>
                                    <Box pb={5}>
                                        <Link to="/login" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                            <Button variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', alignItems: 'center', width: '100%', pr: '1rem' }}>
                                                    <Box>
                                                        <ArrowBackIcon />
                                                    </Box>
                                                    <Box>
                                                        Back to login
                                                    </Box>
                                                    <Box></Box>
                                                </Box>
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </form>
                        ) : (
                            <form onSubmit={handleCodeCheck}>
                                <Box sx={{ width: '85%', m: '0 auto', color: 'inherit', textAlign: 'center' }}>
                                    <Box>
                                        <h5>Please type in the reset code emailed to you below.</h5>
                                    </Box>
                                    <hr />
                                    <Box sx={{ width: '85%', m: '0 auto', color: 'inherit' }}>
                                        <p style={{ textAlign: 'center' }}>If you did not receive a code,</p>
                                        <p>please either check your spam folder or request a new email to be sent.</p>
                                    </Box>
                                </Box>
                                <Box px={10} sx={{
                                    textAlign: 'center',
                                    backgroundColor: mode === 'dark' ? 'darkgrey' : 'white'
                                }}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Box mb={5}>
                                            <TextField
                                                fullWidth
                                                id='codeInput'
                                                label='Type Password Reset Code Here'
                                                value={codeInput}
                                                onClick={() => {
                                                    setErrorCheck(false);
                                                    setError('');
                                                }}
                                                onChange={(e) => setCodeInput(e.target.value)}
                                                onBlur={() => {
                                                    if (!codeInput) {
                                                        setErrorCheck(true);
                                                        setError('Incorrect Code');
                                                    }
                                                }}
                                                margin="dense"
                                                variant="standard"
                                                helperText={error}
                                                error={errorCheck}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        padding: '4vh 0',
                                        filter: 'drop-shadow(0px 1px .5px gray)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <Box sx={{ width: '100%' }}>
                                            <Button type='submit' variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }} disabled={!codeInput}>
                                                <div>
                                                    {
                                                        loadingEmail ? (
                                                            <>
                                                                Submitting Reset Code...
                                                            </>
                                                        ) : (
                                                            <>
                                                                Submit Reset Code
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box pb={5}>
                                        <Button variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }} onClick={() => setStep(1)}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', alignItems: 'center', width: '100%', pr: '1rem' }}>
                                                <Box>
                                                    <ArrowBackIcon />
                                                </Box>
                                                <Box>
                                                    Go back to resend email
                                                </Box>
                                                <Box></Box>
                                            </Box>
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                </Box>
            </Box>
        </Box>)
    );
};

export default ForgotPassword;