import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Modal, Paper, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import { apiRoute } from '../../App';
import { CaseContext } from '../../context/CaseContext';
import { SystemContext } from '../../context/SystemContext';

import ViolationsBox from '../../components/sections/violationsTab/ViolationsBox';
import FeeFinesBox from '../../components/sections/violationsTab/FeeFinesBox';
import ViolationModals from '../../components/modals/views/ViolationModals';

const ViolationsView = (props) => {
    const { updateBalanceRows,
        updateCaseBalance,
        state,
        ts,
        handleFieldChange,
        violationModal,
        setViolationModal,
        violationModalType,
        setViolationModalType,
        violationModalWidth,
        setViolationModalWidth,
        statuteRows,
        violationRows,
        setViolationRows,
        violationView,
        setViolationView,
        violationData,
        setViolationData
    } = props;

    const {
        violationId,
        setViolationId,
        citationView,
        setCitationView,
        setFeeFineRows,
        feeFineRows,
        setLoadingFF,
        setChargesRows,
        vioTransactionTbl,
        caseView
    } = useContext(CaseContext);

    const { agencyCode } = useContext(SystemContext);

    const { caseNumber } = useParams();

    const [caseType, setCaseType] = useState(null);
    const [statuteId, setStatuteId] = useState(null);

    const [feeRules, setFeeRules] = useState([]);

    const [description, setDescription] = useState('');

    // LOADING STATE FOR ADDING VIOLATION
    const [addVioLoad, setAddVioLoad] = useState(false);

    // MODAL STATES
    const [loading, setLoading] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedFee, setSelectedFee] = useState({});

    const [assessDisplay, setAssessDisplay] = useState('');
    const [suspendDisplay, setSuspendDisplay] = useState('');
    const [comments, setComments] = useState('');
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [initVioFeeCharge, setInitVioFeeCharge] = useState(false);
    const [caseLevelFees, setCaseLevelFees] = useState(false);



    const handleOpenViolationModal = (type, width, disabled) => {
        if (type === 'addViolation') {
            setButtonsDisabled(disabled);
        }
        if (type === 'violationInfo') {
            setButtonsDisabled(disabled);
        }
        setViolationModalType(type);
        setViolationModalWidth(width);
        setViolationModal(true)
    };

    const handleCloseViolationModal = () => {
        setViolationModalType('');
        setViolationModalWidth('');
        setViolationModal(false);
        if (!!violationId) {
            setViolationId(null);
        }
        setInitVioFeeCharge(false);
        setViolationData({});
        setViolationView({});
    };

    const [dwiState, setDwiState] = useState({
        dwiTestingAgency: citationView.dwiTestingAgency || '',
        dwiTestEqAgency: citationView.dwiTestEqAgency || '',
        declinedDwiTest: citationView.declinedDwiTest,
    });

    const handleDWIChange = (fieldName, fieldValue) => {
        setLoading(true);

        axios.put(`${apiRoute}/api/citationtbls/dwi/${fieldName}/${fieldValue}/${citationView.pkCitationId}`)
            .then(() => {
                setDwiState((prevState) => ({
                    ...prevState,
                    [fieldName]: fieldValue,
                }));
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    };

    //* --------------- CREATE NEW VIOLATION FOR CASE ---------------
    const addViolation = () => {
        if (!statuteId) {
            toast.error('Select Violation Before Pressing ADD VIOLATION', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return; // Exit early if statuteId is not set
        }

        const transactionViolationsRecord = {
            FkViolationId: '',
            ViolationDate: citationView.citDatetime == null ? new Date(caseView.violationDate) : citationView.citDateTime === '' ? new Date(caseView.violationDate) : citationView.citDateTime === undefined ? new Date(caseView.violationDate) : new Date(citationView.citDatetime),
            RsNumber: statuteRows.find(s => s.id === statuteId).rsNumber,
            Description: statuteRows.find(s => s.id === statuteId).description,
            FineAmount: '',
            CostAmount: '',
            FeeAmount: '',
            EnteredBy: '',
            DateEntered: new Date(),
        };

        const violationInfoView = {
            allowPartialPayment: "",
            amendedSpeed: "",
            amendedSpeedLimit: "",
            begDate: statuteRows.find(s => s.id === statuteId).begDate,
            balance: "",
            comments: "",
            courtAppearRqd: "",
            currentStatute: statuteRows.find(s => s.id === statuteId).rsNumber,
            description: statuteRows.find(s => s.id === statuteId).description,
            chargeType: statuteRows.find(s => s.id === statuteId).chargeType,
            disableDelete: "",
            dispositionCode: "",
            dispositionDate: "",
            dueDate: "",
            endDate: statuteRows.find(s => s.id === statuteId).endDate,
            entityId: "",
            fkCaseId: caseNumber,
            id: "",
            pkViolationId: "",
            remainingBalance: "",
            sentToOnlinePaymentSystem: "",
            totalCosts: "",
            totalFees: "",
            totalFines: "",
            amendmentStatus: "",
            amendmentRecordId: "",
        };

        setAddVioLoad(true);

        axios.post(`${apiRoute}/api/ViolationTbls/AddViolationWithStatutes`,
            {
                pkCaseId: caseNumber,
                pkStatuteId: statuteId,
                caseType: caseType,
                pkCitId: citationView.pkCitationId
            }).then((res) => {
                updateCaseBalance();
                updateBalanceRows();
                setViolationId(null);
                setCaseType(null);
                setStatuteId(null);
                transactionViolationsRecord.FkViolationId = res.data.pkViolationId;
                getViolationFeeFines(res.data.pkViolationId)
                setViolationData(res.data);
                violationInfoView.currentStatute = res.data.currentStatute;
                violationInfoView.id = res.data.pkViolationId;
                violationInfoView.pkViolationId = res.data.pkViolationId;

                return axios.get(`${apiRoute}/api/FeeFineTbls/ffTotals/${res.data.pkViolationId}/${citationView.pkCitationId}`);
            })
            .then((ffRes) => {
                transactionViolationsRecord.FineAmount = ffRes.data.fineTotal;
                transactionViolationsRecord.CostAmount = ffRes.data.costTotal;
                transactionViolationsRecord.FeeAmount = ffRes.data.feeTotal;

                violationInfoView.totalCosts = ffRes.data.costTotal;
                violationInfoView.totalFines = ffRes.data.fineTotal;
                violationInfoView.totalFees = ffRes.data.feeTotal;
                violationInfoView.balance = ffRes.data.costTotal + ffRes.data.fineTotal + ffRes.data.feeTotal;
                violationInfoView.remainingBalance = ffRes.data.costTotal + ffRes.data.fineTotal + ffRes.data.feeTotal;

                setViolationView(violationInfoView);
                return axios.post(`${apiRoute}/api/transactionViolationsTbls/${Number(caseNumber)}`, transactionViolationsRecord);
            })
            .then((data) => {
                setInitVioFeeCharge(true);
                toast.success('Violation added, click close when done.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCaseLevelFees(caseLevelFees => {
                    if (caseLevelFees) {
                        return true
                    } else {
                        return false
                    }
                });
            })
            .catch((err) => {
                setInitVioFeeCharge(false);
                console.log("ADD VIO❌", err);
                toast.error(`Error ${err?.response?.status} while trying to add a violation.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => {
                setAddVioLoad(false);
                //handleOpenViolationModal('violationInfo', '50%', false);
                setCaseLevelFees(caseLevelFees => {
                    if (caseLevelFees) {
                        return true
                    } else {
                        return false
                    }
                });
            });
    };

    //* --------------- AMEND TO NEW VIOLATION FOR CASE ---------------
    const amendToViolation = () => {
        if (!statuteId) {
            toast.error('Select Violation Before Pressing ADD VIOLATION', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return; // Exit early if statuteId is not set
        }

        setViolationView({});

        axios.post(`${apiRoute}/api/violationtbls/AmendViolation`,
            {
                caseType: caseType.toString(),
                pkCaseId: parseInt(caseNumber),
                pkStatuteId: Number(statuteId),
                pkCitId: Number(citationView.pkCitationId),
                violationId: Number(violationId)
            })
            .then((res) => {
                updateCaseBalance();
                updateBalanceRows();
                setViolationId(null);
                setCaseType(null);
                setStatuteId(null);
                getViolationFeeFines(res.data.newAmendmentRecord.amendedToViolation);
                setViolationData(res.data.newViolation);
                setViolationView({
                    allowPartialPayment: res.data.newViolation?.allowPartialPayment ?? null,
                    amendedSpeed: res.data.newViolation?.amendedSpeed ?? 0,
                    amendedSpeedLimit: res.data.newViolation?.amendedSpeedLimit ?? 0,
                    begDate: statuteRows.find(s => s.id === res.data.newViolation.fkStatuteFineId)?.begDate ?? '1900-01-01',
                    balance: feeFineRows.reduce(
                        (acc, value) => acc + value.assessAmount
                        , violationView?.balance),
                    comments: res.data.newViolation.comments,
                    courtAppearRqd: res.data.newViolation.court,
                    currentStatute: statuteRows.find(s => s.id === res.data.newViolation.fkStatuteFineId)?.rsNumber,
                    description: statuteRows.find(s => s.id === res.data.newViolation.fkStatuteFineId)?.description,
                    chargeType: statuteRows.find(s => s.id === res.data.newViolation.fkStatuteFineId)?.chargeType,
                    disableDelete: res.data.newViolation?.disableDelete ?? '',
                    dispositionCode: res.data.newViolation?.dispositionCode ?? '',
                    dispositionDate: res.data.newViolation?.dispositionDate ?? '',
                    dueDate: "",
                    endDate: statuteRows.find(s => s.id === res.data.newViolation.fkStatuteFineId)?.endDate ?? '2099-12-31',
                    entityId: res.data.newViolation?.entityId,
                    fkCaseId: caseNumber,
                    id: res.data.newViolation.pkViolationId,
                    pkViolationId: res.data.newViolation.pkViolationId,
                    remainingBalance: feeFineRows.reduce(
                        (acc, value) => acc + (value.assessAmount - value.suspendAmount)
                        , 0),
                    sentToOnlinePaymentSystem: res.data.newViolation?.sentToOnlinePaymentSystem ?? '',
                    totalCosts: feeFineRows.reduce((acc, value) => value.isCourtCost === "Y" && acc + (value.assessAmount - value.suspendAmount), 0),
                    totalFees: feeFineRows.reduce((acc, value) => value.isCourtCost === "N" && acc + (value.assessAmount - value.suspendAmount), 0),
                    totalFines: feeFineRows.reduce((acc, value) => value.isCourtCost == null && acc + (value.assessAmount - value.suspendAmount), 0),
                    amendmentStatus: res?.data?.newAmendmentRecord?.status,
                    amendmentRecordId: res?.data?.newAmendmentRecord?.pkAmendmentId,
                    amendedFromViolation: res?.data?.newViolation?.value?.amendedFromViolation,
                });
            })
            .then(() => {
                setInitVioFeeCharge(true);
                toast.success('Violation amended, click close when done.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                setInitVioFeeCharge(false);
                console.log("ADD VIO❌", err);
                toast.error(`Error ${err?.response?.status} while trying to add a violation.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => {
                setAddVioLoad(false);
                //setViolationModalType('violationInfo');
            });
    }

    //* --------------- DELETE VIOLATION FROM CASE ---------------
    const deleteViolation = (e, rowId) => {
        e.stopPropagation();
        setLoading(true);
        axios.delete(`${apiRoute}/api/ViolationTbls/${rowId}`)
            .then((res) => {
                setViolationRows(violationRows.filter(r => r.id !== rowId));
                setChargesRows(violationRows.filter(r => r.id !== rowId));
                updateBalanceRows();
                updateCaseBalance();
                if (violationId === rowId) {
                    setFeeFineRows([]);
                }
                setViolationId(null);
                handleCloseViolationModal();
            })
            .catch((err) => {
                console.log('err trying to delete violation', err);
            })
            .finally(() => setLoading(false))
    };

    //* --------------- API CALL TO GET ALL FEE FINES ATTACHED TO VIOLATION ---------------
    const getViolationFeeFines = (id) => {
        setLoadingFF(true);
        setFeeFineRows([]);
        if (id !== null && typeof (id) !== Array && typeof (id) === Object) {
            id = id.id;
        }
        axios.get(`${apiRoute}/api/FeeFineTbls/FkViolationId/${id}`)
            .then((res) => {
                const rows = res.data?.feeFines.map(item => ({
                    ...item,
                    id: item.pkFeeFineId,
                    collected: res.data?.collections?.filter(d => d?.fkFeeFineId === item?.pkFeeFineId)?.reduce((accumulator, collection) => accumulator += collection.disburseAmount, 0.00) ?? 0.00,
                    category: item.applyTo === "OVERPAYMENT" ? "OVERPAYMENT" : item.payOrderHeirarchy === 2 ? 'FINE' : item.payOrderHeirarchy === 1 ? 'COST' : 'OTHER'
                }));
                const violation = rows.findIndex(r => r.applyTo === "ORIGINAL CHARGE");
                if (violation > -1) {
                    rows[violation].collected = rows.filter(r => r.payOrderHeirarchy === 2 && r.applyTo !== "ORIGINAL CHARGE").reduce((accumulator, value) => accumulator += value.collected, 0.00) ?? 0.00;
                }
                setFeeFineRows(rows);
            })
            .catch((err) => {
                console.log(`error getting fine and fees for violation`, err)
            })
            .finally(() => setLoadingFF(false))
    };

    //* --------------- DELETE FEE FINES FROM VIOLATION ---------------
    const deleteAllFees = async (e, rowId) => {
        e.stopPropagation();
        setLoading(true);
        try {
            await axios.delete(`${apiRoute}/api/FeeFineTbls/FkViolationId/${rowId}`);

            // Remove the deleted rows from the table by filtering the data
            setFeeFineRows(feeFineRows.filter(row => row.fkViolationId !== rowId));
            updateBalanceRows();
            updateCaseBalance();
            handleCloseViolationModal();
            await toast.success(`All fees deleted for violation ${violationView.description}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            console.log("DELETE ERROR", err);
        } finally {
            setLoading(false);
        }
    };

    //* --------------- DELETE SELECTED FEE FINE FROM VIOLATION ---------------
    const deleteFee = (e, row) => {
        e.preventDefault();
        setLoading(true);
        axios.delete(`${apiRoute}/api/feefinetbls/${row.id}`)
            .then((res) => {
                const updatedFeeFines = feeFineRows.filter(feeFine => feeFine.id !== row.id);
                setFeeFineRows(updatedFeeFines);
                updateBalanceRows();
                updateCaseBalance();
                toast.success('Fee/Fine successfully removed', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setDeleteModal(false);
            })
            .catch((err) => {
                console.log('error deleting fee', err)
            })
            .finally(() => setLoading(false))
    };

    //* --------------- FUNCTION TO SUSPEND ALL FEES AND FINES FOR SELECTED VIOLATION ---------------
    const suspendFeeFines = () => {
        // Create a new array by mapping over the feeFineRows and updating the suspendAmount
        const updatedRows = feeFineRows?.map(row => {
            if (row.applyTo === "FEE") {
                const newRow = {
                    ...row,
                    suspendAmount: row.assessAmount
                }
                return newRow
            }
            return row;
        });

        const selectedVio = violationRows?.find(({ pkViolationId }) => pkViolationId === violationId)

        // Update the feeFineRows state with the updatedRows
        setFeeFineRows(updatedRows);
        setLoading(true);
        axios.put(`${apiRoute}/api/FeeFineTbls/SaveChanges`, updatedRows)
            .then((res) => {
                updateCaseBalance();
                updateBalanceRows();
                toast.success(`Fees for violation ${selectedVio.currentStatute} have been suspended`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                toast.error(`Error suspending fees for violation ${selectedVio.currentStatute}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error('suspend error', err)
            })
            .finally(() => {
                setLoading(false)
                handleCloseViolationModal();
            })

    };

    const revertFeeFineSuspendedCost = () => {
        // Create a new array by mapping over the feeFineRows and updating the suspendAmount
        const updatedRows = feeFineRows?.map(row => {
            if (row.applyTo === "FEE") {
                const newRow = {
                    ...row,
                    suspendAmount: 0.00
                }
                return newRow
            }
            return row;
        });

        const selectedVio = violationRows?.find(({ pkViolationId }) => pkViolationId === violationId)

        // Update the feeFineRows state with the updatedRows
        setFeeFineRows(updatedRows);
        setLoading(true);
        axios.put(`${apiRoute}/api/FeeFineTbls/SaveChanges`, updatedRows)
            .then((res) => {
                updateCaseBalance();
                updateBalanceRows();
                toast.success(`Fees for violation ${selectedVio.currentStatute} have been reverted back to their original amount`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                toast.error(`Error reverting suspendended fees for violation ${selectedVio.currentStatute}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error('revert suspend error', err);
            })
            .finally(() => {
                setLoading(false)
                handleCloseViolationModal();
            })
    };

    const revertCleared = async () => {
        const selectedVio = violationRows?.find(({ pkViolationId }) => pkViolationId === violationId);
        try {
            setLoading(true)
            await axios.patch(`${apiRoute}/api/FeeFineTbls/revertCleared/${selectedVio.pkViolationId}`);
            await updateCaseBalance();
            updateBalanceRows();
            await toast.success(`Fees for violation ${selectedVio.description} have been reverted back to their original amount`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
            handleCloseViolationModal();
        }
        catch (e) {
            setLoading(false);
            await toast.error(`Error reverting cleared fees for violation ${selectedVio?.currentStatute ?? selectedVio?.description}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('revertCleared error', e);
        }
    }


    const [editSpeed, setEditSpeed] = useState(false);
    const [speedLimit, setSpeedLimit] = useState('0');
    const [recordedSpeed, setRecordedSpeed] = useState('0');

    useEffect(() => {
        if (!!violationRows?.length > 0) {
            const vioView = violationRows?.filter((row) => row?.pkViolationId === violationView?.pkViolationId);
            setViolationView(vioView[0]);
            setSpeedLimit(violationView?.amendedSpeedLimit);
            setRecordedSpeed(violationView?.amendedSpeed);
        }
    }, [violationRows, caseLevelFees, setViolationView]);

    useEffect(() => {
        updateBalanceRows();
    }, [feeFineRows, caseNumber]);

    const addFeesCheck = () => {
        return feeFineRows.length > 0 && feeFineRows.some(({ payOrderHeirarchy }) => payOrderHeirarchy !== 2);
    };

    const addSuspendedCheck = () => {
        return feeFineRows.length > 0 && feeFineRows.filter(ff => ff.payOrderHeirarchy !== 2).every(({ assessAmount, suspendAmount }) => assessAmount === suspendAmount);
    }

    return (
        <Box sx={{ width: '99%', m: '1vh auto' }}>
            <ViolationsBox
                getViolationFeeFines={getViolationFeeFines}
                handleOpen={handleOpenViolationModal}
                setCaseType={setCaseType}
                violationId={violationId}
                updateBalanceRows={updateBalanceRows}
                updateCaseBalance={updateCaseBalance}
                setDescription={setDescription}
                setViolationView={setViolationView}
                setViolationData={setViolationData}
                violationView={violationView}
                violationData={violationData}
                setFeeRules={setFeeRules}
                state={state}
                handleFieldChange={handleFieldChange}
                comments={comments}
                setComments={setComments}
                vioTransactionTbl={vioTransactionTbl}
                feeFineRows={feeFineRows}
                revertCleared={revertCleared}
                statuteRows={statuteRows}
                violationRows={violationRows}
                setViolationRows={setViolationRows}
                addFeesCheck={addFeesCheck}
                addSuspendedCheck={addSuspendedCheck}
            />
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: '2vh', pb: '.25vh' }} elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: 'flex' }}>
                            <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>DWI</h6>
                        </Paper>
                        <Box sx={{ width: '95%', margin: '1vh auto', mb: '0vh' }}>
                            <Grid container mt={2.5} mb={1.5} sx={{ display: "flex", gap: '2rem' }}>
                                <Grid item sm={2.5}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={loading}
                                                checked={dwiState.declinedDwiTest === 'Y'}
                                                onChange={(e) => {
                                                    const value = e.target.checked ? 'Y' : 'N';
                                                    dwiState.declinedDwiTest = value
                                                    setDwiState({ ...dwiState, dwiTestEqAgency: '', dwiTestingAgency: '' });
                                                    setCitationView({ ...citationView, declinedDwiTest: value })
                                                    handleDWIChange('declinedDwiTest', value);
                                                }}
                                            />}
                                        disabled={!state.isActive | state.isDeleted}
                                        label="Declined DWI Test"
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || dwiState.declinedDwiTest === 'Y'}
                                        sx={ts}
                                        id='dwiTestEqAgency'
                                        label='DWI Testing EQ Agency'
                                        name='dwiTestEqAgency'
                                        value={dwiState.dwiTestEqAgency}
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        onChange={(e) => {
                                            setDwiState({ ...dwiState, dwiTestEqAgency: e.target.value })
                                            setCitationView({ ...citationView, dwiTestEqAgency: e.target.value })
                                            handleDWIChange('dwiTestEqAgency', e.target.value);
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '10em',
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={'NONE'}>N/A</MenuItem>
                                        {
                                            agencyCode?.map(({ code, description }) => {
                                                return <MenuItem key={code} value={code}>{description}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={loading || dwiState.declinedDwiTest === 'Y'}
                                        sx={ts}
                                        id='dwiTestingAgency'
                                        label='DWI Testing Agency'
                                        name='dwiTestingAgency'
                                        value={dwiState.dwiTestingAgency}
                                        inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                        onChange={(e) => {
                                            setDwiState({ ...dwiState, dwiTestingAgency: e.target.value })
                                            setCitationView({ ...citationView, dwiTestingAgency: e.target.value })
                                            handleDWIChange('dwiTestingAgency', e.target.value);
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '10em',
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={'NONE'}>N/A</MenuItem>
                                        {
                                            agencyCode?.map(({ code, description }) => {
                                                return <MenuItem key={code} value={code}>{description}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                <FeeFinesBox ts={ts} feeFineRows={feeFineRows} caseNumber={caseNumber} />
            </Box>
            <Modal open={violationModal} sx={{ width: violationModalWidth, margin: "5vh auto" }}>
                <ViolationModals
                    ts={ts}
                    modalType={violationModalType}
                    setModalType={setViolationModalType}
                    handleClose={handleCloseViolationModal}
                    violationView={violationView}
                    setViolationView={setViolationView}
                    violationData={violationData}
                    loading={loading}
                    setLoading={setLoading}
                    updateBalanceRows={updateBalanceRows}
                    updateCaseBalance={updateCaseBalance}
                    // --- modalType = addViolation props ---
                    setStatuteId={setStatuteId}
                    statuteId={statuteId}
                    setCaseType={setCaseType}
                    statuteRows={statuteRows}
                    addViolation={addViolation}
                    addVioLoad={addVioLoad}
                    // --- modalType = sentencing props ---
                    description={description}
                    // --- modalType = violationInfo props ---
                    deleteFee={deleteFee}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    // --- modalType = delete props ---
                    deleteViolation={deleteViolation}
                    // --- modalType = addFee props ---
                    feeRules={feeRules}
                    // --- modalType = deleteFees props ---
                    deleteAllFees={deleteAllFees}
                    // --- modalType = suspendAll props ---
                    suspendFeeFines={suspendFeeFines}
                    setFeeRules={setFeeRules}
                    selectedFee={selectedFee}
                    setSelectedFee={setSelectedFee}
                    assessDisplay={assessDisplay}
                    setAssessDisplay={setAssessDisplay}
                    suspendDisplay={suspendDisplay}
                    setSuspendDisplay={setSuspendDisplay}
                    comments={comments}
                    setComments={setComments}
                    buttonsDisabled={buttonsDisabled}
                    initVioFeeCharge={initVioFeeCharge}
                    setInitVioFeeCharge={setInitVioFeeCharge}
                    amendToViolation={amendToViolation}
                    setViolationData={setViolationData}
                    vioTransactionTbl={vioTransactionTbl}
                    revertFeeFineSuspendedCost={revertFeeFineSuspendedCost}
                    caseLevelFees={caseLevelFees}
                    setCaseLevelFees={setCaseLevelFees}
                    editSpeed={editSpeed}
                    setEditSpeed={setEditSpeed}
                    speedLimit={speedLimit}
                    setSpeedLimit={setSpeedLimit}
                    recordedSpeed={recordedSpeed}
                    setRecordedSpeed={setRecordedSpeed}
                    state={state}
                    revertCleared={revertCleared}
                    getViolationFeeFines={getViolationFeeFines}
                />
            </Modal>
        </Box>
    )
}

export default ViolationsView;