import React, { useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, LinearProgress } from '@mui/material';
import {
    DataGridPremium,
    GridToolbar,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { RiDeleteBinLine } from "react-icons/ri";

import { CaseContext } from '../../../../context/CaseContext';
import UserContext from '../../../../context/UserContext';
import { commaMoneyFormat } from '../../../../Utils';

const FeesFinesView = ({ setConfirmChange, setSelectedFee, openDelete, setAssessDisplay, setSuspendDisplay, violationView }) => {

    const apiRef = useGridApiRef();

    const { loadingFF, feeFineRows } = useContext(CaseContext);

    const { useFeeSchedule } = useContext(UserContext);

    const columns = [
        {
            field: 'description',
            headerName: 'Description',
            flex: 3,
            diplay: 'flex',
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1.5,
            display: 'flex',
            align: 'center'
        },
        {
            field: 'payOrderHeirarchy',
            headerName: 'Sort Order',
            flex: 1,
            align: 'center',
            headerAlign: 'right'
        },
        {
            field: 'payOrder',
            headerName: 'Pay Order',
            flex: 1,
            align: 'center'
        },
        {
            field: 'assessAmount',
            headerName: 'Assessed',
            flex: 1,
            type: 'number',
            renderCell: (params) => {
                const isExpandableRow = params.rowNode?.type === "group";
                return (
                    <>{isExpandableRow ? null : params.value >= 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value || 0.00) : null}</>
                )
            },
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'suspendAmount',
            headerName: 'Suspended',
            flex: 1,
            type: 'number',
            renderCell: (params) => {
                const isExpandableRow = params.rowNode?.type === "group";
                return (
                    <>{isExpandableRow ? null : params.value >= 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value || 0.00) : null}</>
                )
            },
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'collected',
            headerName: 'Collected',
            flex: 1,
            type: 'number',
            renderCell: (params) => {
                const isExpandableRow = params.rowNode?.type === "group";
                return (
                    <span
                    >
                        <>{isExpandableRow ? null : params.value >= 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value || 0.00) : null}</>
                    </span>
                )
            },
            align: "right",
            headerAlign: "right"
        },
        {
            field: 'edit',
            headerName: 'Edit',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                const isExpandableRow = params.rowNode?.type === "group";
                return (
                    !isExpandableRow && <>
                        <Button
                            fullWidth
                            onClick={async (event) => {
                                await event.stopPropagation();
                                await setSelectedFee(params.row);
                                await setAssessDisplay(commaMoneyFormat(params.row.assessAmount))
                                await setSuspendDisplay(commaMoneyFormat(params.row.suspendAmount))
                                setConfirmChange(true);
                            }}
                            variant="contained"
                            sx={{ backgroundColor: "steelblue", color: 'white' }}
                            disabled={(violationView.remainingBalance <= 0 && params.row.assessAmount !== 0) | (violationView.remainingBalance <= 0 && params.row.assessAmount !== 0 && (params.row.collected === (params.row.assessed - params.row.suspended))) | params.row.description === 'AMENDED TO DISMISSED'}
                        >
                            <EditIcon sx={{ fontSize: 'large' }} />
                        </Button>
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                const isExpandableRow = params.rowNode?.type === "group";
                return (
                    !isExpandableRow && <>
                        {
                            (params.row.applyTo === "FEE" || (params.row.applyTo === "FINE" && useFeeSchedule === 0)) && (
                                <Button
                                    fullWidth
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        openDelete(params.row);
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: 'white', p: '.5rem' }}
                                    disabled={violationView.remainingBalance <= 0 | params.row.applyTo === 'FINE'}
                                >
                                    <RiDeleteBinLine sx={{ fontSize: 'large' }} />
                                </Button>
                            )
                        }
                    </>
                )
            }
        },
    ];


    const sortedRows = [...feeFineRows].sort((a, b) => {
        if (a.payOrderHeirarchy === 2 && b.payOrderHeirarchy !== 2) {
            return -1;
        }
        if (b.payOrderHeirarchy === 2 && a.payOrderHeirarchy !== 2) {
            return 1;
        }
        if (a.payOrderHeirarchy === 2 && b.payOrderHeirarchy === 2) {
            if (a.description === violationView.description) {
                return -1;
            }
            if (a.descrption > b.description) {
                return -1;
            }
            return 1;
        }
        if (a.payOrderHeirarchy === 1 && a.payOrder > b.payOrder) {
            return 1;
        }
        if (a.payOrderHeirarchy === 1 && a.payOrder < b.payOrder) {
            return -1;
        }
        if (a.payOrderHeirarchy === 3) {
            return 1;
        }
        return 0;
    });

    // documentation for group columns
    //const initialState = useKeepGroupedColumnsHidden({
    //    apiRef,
    //    initialState: {
    //        rowGrouping: {
    //            model: groupingModel
    //        }
    //    }
    //});

    //useEffect(() => {
    //    if (sortedRows.length > 0) {
    //        const fines = sortedRows.filter(row => row.category === 'FINE');
    //        console.log('fines: ', fines);

    //        return fines.length > 0 ? setGroupingModel(['category']) : setGroupingModel([]);
    //    }

    //}, [feeFineRows, sortedRows]);

    //useEffect(() => {
    //    // Ensure grid is initialized before trying to group
    //    if (sortedRows.length > 0) {
    //        if (apiRef.current) {
    //            const fineRows = sortedRows.filter((row) => row.category === 'FINE');

    //            // If there are rows with category 'FINE', set the groupBy model dynamically
    //            if (fineRows.length > 0) {
    //                apiRef.current.setState((prevState) => ({
    //                    ...prevState,
    //                    rowGrouping: {
    //                        ...prevState.rowGrouping,
    //                        model: ['category'],  // Group by 'category' if 'FINE' rows exist
    //                    },
    //                }));
    //                apiRef.current.forceUpdate(); // Force grid to re-render with new state
    //            } else {
    //                // Clear grouping if 'FINE' rows do not exist
    //                apiRef.current.setState((prevState) => ({
    //                    ...prevState,
    //                    rowGrouping: {
    //                        ...prevState.rowGrouping,
    //                        model: [], // No grouping if no 'FINE' rows
    //                    },
    //                }));
    //                apiRef.current.forceUpdate(); // Force grid to re-render with new state
    //            }
    //        }
    //    }
    //}, [sortedRows, apiRef]); // Re-run when rows change

    return (
        <Box sx={{ height: '35vh' }}>
            <DataGridPremium
                rows={sortedRows}
                columns={columns}
                apiRef={apiRef}
                defaultGroupingExpansionDepth={-1}
                disableMultipleRowSelection
                slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
                loading={loadingFF}
                getRowId={(row) => row.id}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true }
                    },
                }}
                localeText={{ noRowsLabel: 'Select a violation above to display or add fees and fines to selected violation' }}
                initialState={{
                    rowGrouping: {
                        model: ['category']
                    },
                    pagination: { paginationModel: { pageSize: 75 } },
                    density: 'compact'
                }}
                autosizeOptions={{columns: ['category', 'description'], includeOutliers: true, includeHeaders: false} }
                sx={{
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                        marginTop: '1em',
                        marginBottom: '1em'
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    maxHeight: '35vh',
                }}
            />
        </Box>
    );
};

export default FeesFinesView;