import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Box, Button, InputAdornment, Paper, Tooltip, TextField, Modal, LinearProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { MdOutlineArrowBack } from "react-icons/md";

import { toast } from 'react-toastify';

import AddChargeViewTable from '../../tables/views/violationsTab/AddChargeViewTable';
import FeesFinesView from '../../tables/views/violationsTab/FeesFinesViewTable';
import AddFeeTable from '../../tables/views/violationsTab/AddFeeTable';
import Sentencing from '../components/violationsTab/Sentencing';

import { CaseContext } from '../../../context/CaseContext';
import { apiRoute } from '../../../App';
import CaseTypeFeesTable from '../../tables/views/violationsTab/CaseTypeFeesTable';
import UserContext from '../../../context/UserContext';
import { commaMoneyFormat, formatDate, formattedDate } from '../../../Utils';



const ViolationModals = (props) => {

    const {
        ts,
        modalType,
        setModalType,
        handleClose,
        violationView,
        setViolationView,
        violationData,
        loading,
        setLoading,
        updateBalanceRows,
        updateCaseBalance,
        // --- modalType = addViolation props ---
        setCaseType,
        setStatuteId,
        statuteId,
        statuteRows,
        addViolation,
        addVioLoad,
        // --- modalType = sentencing props ---
        description,
        // --- modalType = violationInfo props ---
        deleteFee,
        deleteModal,
        setDeleteModal,
        // --- modalType = delete props ---
        deleteViolation,
        // --- modalType = addFee props ---
        feeRules,
        // --- modalType = deleteFees props ---
        deleteAllFees,
        // --- modalType = suspendAll props ---
        suspendFeeFines,
        setFeeRules,
        selectedFee,
        setSelectedFee,
        setSuspendDisplay,
        setAssessDisplay,
        suspendDisplay,
        assessDisplay,
        comments,
        setComments,
        buttonsDisabled,
        initVioFeeCharge,
        setInitVioFeeCharge,
        getStatutes,
        amendToViolation,
        setViolationData,
        vioTransactionTbl,
        revertFeeFineSuspendedCost,
        caseLevelFees,
        setCaseLevelFees,
        state,
        revertCleared,
        getViolationFeeFines
    } = props;

    const { setFeeFineRows, violationDate, setViolationRows, violationRows } = useContext(CaseContext);

    const { entityId, useFeeSchedule, userName } = useContext(UserContext)

    const { caseNumber } = useParams();

    // EDIT VIOLATION COMMENTS
    const [addingComments, setAddingComments] = useState(false);
    const [additionalComments, setAdditionalComments] = useState("");

    // EDIT FEE STATES
    const [confirmChange, setConfirmChange] = useState(false);

    // EDIT SPEED INFO STATES
    const [editSpeed, setEditSpeed] = useState(false);
    const [speedLimit, setSpeedLimit] = useState('0');
    const [recordedSpeed, setRecordedSpeed] = useState('0');

    const closeEditSpeed = () => {
        setSpeedLimit(violationView.amendedSpeedLimit);
        setRecordedSpeed(violationView.amendedSpeed);
        setEditSpeed(false);
    };

    const openDelete = (row) => {
        setSelectedFee(row);
        setDeleteModal(true);
    };

    useEffect(() => {
        if (!!violationRows?.length > 0) {
            const vioView = violationRows?.filter((row) => row?.pkViolationId === violationView?.pkViolationId);
            setViolationView(vioView[0]);
            setSpeedLimit(violationView?.amendedSpeedLimit);
            setRecordedSpeed(violationView?.amendedSpeed);
        }
    }, [violationRows, violationView, caseLevelFees, setViolationView]);

    const getRow = (params) => {
        setStatuteId(params?.row?.id);
        setCaseType(params?.row?.chargeType);
    };

    const editFeeFineRow = () => {
        const newFine = {
            ...selectedFee,
            assessAmount: !!selectedFee.assessAmount ? parseFloat(selectedFee?.assessAmount) : '0.00',
            suspendAmount: !!selectedFee.suspendAmount ? parseFloat(selectedFee?.suspendAmount) : '0.00',
        }

        axios.put(`${apiRoute}/api/feefinetbls/${selectedFee.pkFeeFineId}`, newFine)
            .then(() => {
                setFeeFineRows((prevRows) => {
                    const rowIndex = prevRows.findIndex(row => row.pkFeeFineId === selectedFee.pkFeeFineId);
                    if (rowIndex !== -1) {
                        // Create a copy of the feeFineRows array
                        const updatedRows = [...prevRows];
                        // Update the specific row with newFine
                        updatedRows[rowIndex] = selectedFee;
                        return updatedRows;
                    }

                    return prevRows; // If the row is not found, return the original array
                });
                updateBalanceRows();
                updateCaseBalance();
                getViolationFeeFines(selectedFee.fkViolationId);
                setSelectedFee({});
                // Show a success toast message
                toast.success(`New fine amount saved for ${selectedFee.description}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                console.error(err);
                toast.error(`Error ${err.response.status} saving new fine amount for ${selectedFee.description}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setConfirmChange(false))
    };

    const addFee = () => {
        const newFee = {
            fkViolationId: violationData.pkViolationId,
            fkCaseId: caseNumber,
            grouper: selectedFee.feeGrouper,
            applyTo: selectedFee.applyTo,
            description: selectedFee.description,
            assessAmount: selectedFee.amount,
            suspendAmount: 0,
            new: 'Y',
            fkFeeRuleId: selectedFee.pkFeeRuleId,
            chargeType: selectedFee.chargeType,
        }

        setLoading(true);

        axios.post(`${apiRoute}/api/feefinetbls/`, newFee)
            .then(() => {

                toast.success('Fee added, click close when done.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                updateBalanceRows();
                updateCaseBalance();
                setSelectedFee({});
            })
            .catch((err) => {
                console.error('error adding fee', err)
            })
            .finally(() => setLoading(false))
    };

    const addFees = () => {
        setLoading(true);
        setCaseLevelFees(true);

        axios.post(`${apiRoute}/api/feefinetbls/chargeType/${violationData.pkViolationId}/${caseNumber}/${violationDate}`, selectedFee)
            .then(() => {

                toast.success('Fees added.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                updateBalanceRows();
                updateCaseBalance();
                handleClose();
                setCaseLevelFees(false);
            })
            .catch((err) => {
                console.error('error adding fee', err)
            })
            .finally(() => setLoading(false))
    };

    const updateSpeedingInfo = () => {
        setLoading(true);

        violationView.amendedSpeed = recordedSpeed;
        violationView.amendedSpeedLimit = speedLimit;

        const newVio = {
            ...violationData,
            amendedSpeed: parseInt(recordedSpeed),
            amendedSpeedLimit: parseInt(speedLimit)
        };

        axios.put(`${apiRoute}/api/violationtbls/${violationData.pkViolationId}`, newVio)
            .then(async (res) => {
                const data = res.data;
                const updatedRows = violationRows.map(row => {
                    if (row.pkViolationId === violationView.pkViolationId) {
                        return { ...row, ...newVio };
                    }
                    return row;
                });
                setViolationRows(updatedRows);
                await updateCaseBalance();
                await updateBalanceRows();
                setEditSpeed(false);
                toast.success('Speeding Info Updated', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return data;
            })
            .then((data) => {
                axios.get(`${apiRoute}/api/FeeFineTbls/FkViolationId/${data.pkViolationId}`)
                    .then((res) => {
                        const rows = res.data.map(item => ({
                            ...item,
                            id: item.pkFeeFineId,
                        }));
                        setFeeFineRows(rows);
                    })
            })
            .catch((err) => {
                toast.error(`Error ${err.response.status} while trying to update speeding info`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => { setLoading(false) })
    };

    const saveVioComments = (e) => {
        e.preventDefault();
        setLoading(true);

        // merge comments with additional comments
        let updatedComments;
        if (!comments || comments?.length < 1) {
            updatedComments = `<${userName} ${new Date().toDateString()}> ${additionalComments}`

        } else {
            updatedComments = comments + `\n<${userName} ${new Date().toDateString()}> ${additionalComments}`
        }

        const newVio = {
            ...violationData,
            comments: updatedComments
        };

        axios.patch(`${apiRoute}/api/violationtbls/PostSentence/${violationView.pkViolationId}`, newVio)
            .then(() => {
                toast.success(`Comments for ${violationView.description} updated.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                const index = violationRows.findIndex(v => v.pkViolationId === newVio.pkViolationId);
                if (index !== -1) {
                    const updatedViolationRows = violationRows.map((v, i) =>
                        i === index ? { ...v, comments: newVio.comments } : v
                    );
                    setViolationRows(updatedViolationRows);
                }
                handleClose();
            })
            .catch((err) => {
                toast.error(`Failed to update comments for ${violationView.description}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(err);
            })
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        if (modalType === 'caseLevelFees') {
            setCaseLevelFees(true);
        }
    }, [modalType]);

    return (<>
        {
            modalType === 'addViolation' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh' }} elevation={10}>
                        <h1>Add Violation</h1>
                    </Paper>
                    <Box sx={{ width: "100%", m: "auto", pb: "1vh", pt: "2vh" }}>
                        <Paper sx={{ height: "50vh", overflow: 'hidden' }} elevation={10}>
                            <AddChargeViewTable
                                statuteRows={statuteRows}
                                getRow={getRow}
                                loading={loading}
                                addViolation={addViolation}
                                caseLevelFees={false}
                            />
                        </Paper>
                        <Box sx={{ display: "flex", justifyContent: "right", gap: '.5rem', ml: '1vh', mr: '1vh', mt: '1vh' }}>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={addViolation}>Add Violation</Button>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Close</Button>
                        </Box>

                    </Box>
                    {
                        addVioLoad && <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'amendViolation' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh' }} elevation={10}>
                        <h1>Amend Violation</h1>
                    </Paper>
                    <Box sx={{ width: "99%", m: "auto", pb: "6vh", pt: "2vh" }}>
                        <Paper sx={{ height: "50vh" }} elevation={10}>
                            <AddChargeViewTable
                                statuteRows={statuteRows}
                                getRow={getRow}
                                loading={loading}
                                addViolation={addViolation}
                                modalType={modalType}
                                amendViolation={amendToViolation}
                            />
                            <Box sx={{ display: "flex", justifyContent: "right", gap: '1rem', mt: '1vh' }}>
                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={amendToViolation}>Amend Violation</Button>
                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Close</Button>
                            </Box>
                        </Paper>
                    </Box>
                    {
                        addVioLoad && <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'sentencing' && (
                <Paper elevation={10}>
                    <Paper
                        sx={{
                            display: 'flex',
                            gap: '.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'steelblue',
                            color: 'white',
                            p: '.5vh',
                            mb: '2vh'
                        }}
                        elevation={10}
                    >
                        <h1>
                            <strong>
                                Sentencing Details
                            </strong>
                        </h1>
                        <Tooltip title="Post And Update Sentence Details Here!" placement="top">
                            <InfoIcon />
                        </Tooltip>
                    </Paper>
                    <Box sx={{ width: "99%", m: "auto", p: "1vh" }}>
                        <h5>Violation Selected: {description}</h5>
                        <Box sx={{ height: "57%", pt: '1vh', display: 'flex', flexGrow: '1' }}>
                            <Sentencing
                                handleClose={handleClose}
                                violationData={violationData}
                                ts={ts}
                                state={state}
                            />
                        </Box>
                    </Box>
                </Paper>
            )
        }
        {
            modalType === 'violationInfo' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>{caseLevelFees ? 'Case Level Fee' : 'Violation'} Info</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        {!caseLevelFees &&
                            <Paper sx={{ p: '1vh', mb: '1vh' }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <strong>
                                        <p>Statute: {violationView?.currentStatute || violationView?.description}</p>
                                        <p>Description: {violationView?.description || ''}</p>
                                        <p>Charge Type: {violationView?.chargeType || ''}</p>
                                        {
                                            (violationView?.chargeType === 'SPEED' || violationView?.chargeType === '8' || violationView?.description?.toLowerCase().includes('speed')) && (
                                                <Button onClick={() => setEditSpeed(true)} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Edit Speeding Info</Button>
                                            )
                                        }
                                    </strong>
                                    {violationView?.amendedDate && (
                                        <strong>
                                            <p style={{ color: '#E3D026' }}>Violation Amended To: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.currentStatute}</p>
                                            <p style={{ color: '#E3D026' }}>Description: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.description || ''}</p>
                                            <p style={{ color: '#E3D026' }}>Charge Type: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.chargeType || ''}</p>
                                            <p style={{ color: '#E3D026' }}>Amended Date: {formatDate(violationView?.amendedDate?.split("T")[0]) || ''}</p>
                                        </strong>
                                    )}
                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "2rem", pr: "1rem" }}>
                                        <strong style={{ textAlign: "left" }}>
                                            <p>Fine Total: </p>
                                            <p>Cost Total: </p>
                                            <p>Fee Total: </p>
                                            <p>Balance Due: </p>
                                        </strong>
                                        <strong>
                                            <p>{commaMoneyFormat(violationView?.totalFines)}</p>
                                            <p>{commaMoneyFormat(violationView?.totalCosts)}</p>
                                            <p>{commaMoneyFormat(violationView?.totalFees)}</p>
                                            <p>{commaMoneyFormat(violationView?.remainingBalance)}</p>
                                        </strong>
                                    </Box>
                                </Box>
                            </Paper>
                        }
                        <hr />
                        <h4 style={{ fontWeight: 'bold' }}>
                            {!caseLevelFees ? 'Current Fine, Fees, and Costs for this violation' : 'Current Fees for this Case Level Fee'}
                        </h4>
                        <FeesFinesView
                            setConfirmChange={setConfirmChange}
                            setSelectedFee={setSelectedFee}
                            openDelete={openDelete}
                            setAssessDisplay={setAssessDisplay}
                            setSuspendDisplay={setSuspendDisplay}
                            buttonsDisabled={buttonsDisabled}
                            violationView={violationView}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '1vh', pb: '1vh' }}>
                            <div>
                                <Button
                                    onClick={() => { setModalType('amendExistingViolation') }}
                                    variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', marginRight: '1rem' }}
                                >
                                    Amend Violation
                                </Button>
                                {
                                    !caseLevelFees && (
                                        <Button
                                            onClick={() => { setModalType('addFeesByType') }}
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                            disabled={violationView?.chargeType === 'DISMISSED'
                                                || violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.currentStatute === 'DISMISSED'
                                                || (violationView?.totalCosts === 0 && violationView?.totalFines === 0 && violationView?.totalFees > 0)
                                            }
                                        >
                                            Add Fees by Charge Type
                                        </Button>
                                    )
                                }
                            </div>
                            <Button onClick={() => { handleClose(); setCaseLevelFees(false); setStatuteId(null); }} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Close</Button>
                        </Box>
                    </Box>
                    {
                        editSpeed && (
                            <Modal open={editSpeed} sx={{ width: '30%', margin: "10vh auto" }}>
                                <div>
                                    <Paper elevation={10}>
                                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                                            <h1>
                                                <strong>Edit Speeding Info</strong>
                                            </h1>
                                        </Paper>
                                        <Box p={1}>
                                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="speedLimit"
                                                        label="Speed Limit"
                                                        value={speedLimit | ''}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (/^\d*$/.test(inputValue)) {
                                                                setSpeedLimit(inputValue);
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                            maxLength: 2
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="recordedSpeed"
                                                        label="Recorded Speed"
                                                        value={recordedSpeed | ''}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (/^\d*$/.test(inputValue)) {
                                                                setRecordedSpeed(inputValue);
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                            maxLength: 3
                                                        }}
                                                        error={Number(recordedSpeed) < Number(speedLimit)}
                                                    />
                                                </Box>
                                            </Box>
                                            <hr />
                                            <Box sx={{ display: "flex", justifyContent: "right", gap: '1rem', mt: '1vh' }}>
                                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={updateSpeedingInfo} disabled={Number(recordedSpeed) < Number(speedLimit)}>Update Speeding Info</Button>
                                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={closeEditSpeed}>Cancel</Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                    {
                                        loading &&
                                        <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                                    }
                                </div>
                            </Modal>
                        )
                    }
                    {
                        confirmChange && (
                            <Modal open={confirmChange} sx={{ width: '35%', margin: "10vh auto" }}>
                                <div>
                                    <Paper elevation={10}>
                                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                                            <h1>
                                                <strong>Edit {selectedFee.description}</strong>
                                            </h1>
                                        </Paper>
                                        <Box p={1}>
                                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="assessAmount"
                                                        label="Assess Amount"
                                                        value={assessDisplay}
                                                        onClick={() => setAssessDisplay('')}
                                                        onChange={(e) => {
                                                            setAssessDisplay(e.target.value);
                                                        }}
                                                        onBlur={(e) => {
                                                            if (!e.target.value) {
                                                                setAssessDisplay(commaMoneyFormat(selectedFee.assessAmount))
                                                            } else {
                                                                const value = e.target.value.replace('$', '');
                                                                setSelectedFee({
                                                                    ...selectedFee,
                                                                    assessAmount: isNaN(parseFloat(value).toFixed(2)) ? '0.00' : parseFloat(value).toFixed(2)
                                                                })
                                                                setAssessDisplay(commaMoneyFormat(value))
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        fullWidth
                                                        sx={ts}
                                                        id="suspendAmount"
                                                        label="Suspend Amount"
                                                        value={suspendDisplay}
                                                        onClick={() => setSuspendDisplay('')}
                                                        onChange={(e) => {
                                                            setSuspendDisplay(e.target.value);
                                                        }}
                                                        onBlur={(e) => {
                                                            if (!e.target.value) {
                                                                setSuspendDisplay(commaMoneyFormat(selectedFee.suspendAmount))
                                                            } else {
                                                                const value = e.target.value.replace('$', '');
                                                                setSelectedFee({
                                                                    ...selectedFee,
                                                                    suspendAmount: isNaN(parseFloat(value).toFixed(2)) ? '0.00' : parseFloat(value).toFixed(2)
                                                                })
                                                                setSuspendDisplay(commaMoneyFormat(value))
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                            inputMode: 'numeric',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <hr />
                                            <Box sx={{ display: "flex", justifyContent: "right", gap: '1rem', mt: '1vh' }}>
                                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={editFeeFineRow}>Update {selectedFee.applyTo === 'FINE' ? 'Fine' : 'Fee'}</Button>
                                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={() => setConfirmChange(false)}>Cancel</Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </div>
                            </Modal>
                        )
                    }
                    {
                        deleteModal && (
                            <Modal open={Modal} sx={{ width: '35%', margin: "10vh auto" }}>
                                <div>
                                    <Paper elevation={10}>
                                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                                            <h1>
                                                <strong>Delete Fee Confirmation</strong>
                                            </h1>
                                        </Paper>
                                        <Box p={1}>
                                            <h4 style={{ textAlign: 'center', color: '#ff6961' }}>Are you sure you want to delete this fee?</h4>
                                            <hr />
                                            <Box sx={{ display: 'flex', gap: '1rem', mb: '1vh' }}>
                                                <strong>Description: </strong>
                                                <p>{selectedFee.description}</p>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: '1rem', mb: '1vh' }}>
                                                <strong>Assess Amount: </strong>
                                                <p>{commaMoneyFormat(selectedFee.assessAmount)}</p>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: '1rem', mb: '1vh' }}>
                                                <strong>Suspend Amount: </strong>
                                                <p>{commaMoneyFormat(selectedFee.suspendAmount)}</p>
                                            </Box>
                                            <hr />
                                            <Box sx={{ display: "flex", justifyContent: "right", gap: '1rem', mt: '1vh' }}>
                                                <Button disabled={loading} variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={(e) => deleteFee(e, selectedFee)}>Delete Fee</Button>
                                                <Button disabled={loading} variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={() => setDeleteModal(false)}>Cancel</Button>
                                            </Box>
                                        </Box>
                                        {
                                            loading && (
                                                <LinearProgress sx={{ width: '99%', m: '0 auto' }} />
                                            )
                                        }
                                    </Paper>
                                </div>
                            </Modal>
                        )
                    }
                </Paper>
            )
        }
        {
            modalType === 'amendExistingViolation' && (
                <Paper elevation={10}>
                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>Amended Violation</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <Paper sx={{ p: '1vh', mb: '1vh' }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <strong>
                                    <p>Statute: {violationView?.currentStatute || violationView?.description}</p>
                                    <p>Description: {violationView?.description || ''}</p>
                                    <p>Charge Type: {violationView?.chargeType || ''}</p>
                                </strong>
                                {violationView?.amendedDate && (
                                    <strong>
                                        <p style={{ color: '#E3D026' }}>Violation Amended To: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.currentStatute}</p>
                                        <p style={{ color: '#E3D026' }}>Description: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.description || ''}</p>
                                        <p style={{ color: '#E3D026' }}>Charge Type: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.chargeType || ''}</p>
                                        <p style={{ color: '#E3D026' }}>Charge Type: {violationRows?.find(i => vioTransactionTbl.amendedToViolation === i.id)?.amendedDate || ''}</p>
                                    </strong>
                                )}
                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "2rem", pr: "1rem" }}>
                                    <strong style={{ textAlign: "left" }}>
                                        <p>Fine Total: </p>
                                        <p>Cost Total: </p>
                                        <p>Fee Total: </p>
                                        <p>Balance Due: </p>
                                    </strong>
                                    <strong>
                                        <p>{commaMoneyFormat(violationView?.totalFines)}</p>
                                        <p>{commaMoneyFormat(violationView?.totalCosts)}</p>
                                        <p>{commaMoneyFormat(violationView?.totalFees)}</p>
                                        <p>{commaMoneyFormat(violationView?.remainingBalance)}</p>
                                    </strong>
                                </Box>
                            </Box>
                        </Paper>
                        <hr />
                        <h4 style={{ fontWeight: 'bold' }}>
                            Current Fine, Fees, and Costs for this violation:
                        </h4>
                        <FeesFinesView
                            setConfirmChange={setConfirmChange}
                            setSelectedFee={setSelectedFee}
                            openDelete={openDelete}
                            setAssessDisplay={setAssessDisplay}
                            setSuspendDisplay={setSuspendDisplay}
                            buttonsDisabled={buttonsDisabled}
                            violationView={violationView}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '1vh', pb: '1vh' }}>
                            <div>
                                <Button
                                    onClick={() => { setModalType('violationInfo') }}
                                    variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', marginRight: '1rem' }}
                                    startIcon={<MdOutlineArrowBack />}
                                >
                                    Back
                                </Button>
                                <Button onClick={() => { setModalType('amendViolation'); }} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mr: '1vw' }}>Amend To Violation</Button>
                                <Button onClick={() => { setModalType('addFeesByType') }} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Add Fees by Charge Type</Button>
                            </div>
                            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Close</Button>
                        </Box>
                    </Box>
                </Paper>
            )
        }
        {
            modalType === 'delete' && (
                <Paper elevation={10}>
                    <Paper sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                        <h1>
                            <strong>Delete Violation</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <h3 style={{ textAlign: 'center' }}>You are about to delete the {violationView?.description} violation from this case.</h3>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={(e) => { deleteViolation(e, violationView.pkViolationId); setViolationData({}); setViolationView({}) }}>Delete Violation</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'addFee' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>Add Fee</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <AddFeeTable
                            feeRules={feeRules}
                            setSelectedFee={setSelectedFee}
                        />
                        <hr />
                        <Box sx={{ display: "flex", justifyContent: "right", pb: '1vh', gap: '.5rem' }}>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={addFee}>Add Fee</Button>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={handleClose}>Close</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'addFeesByType' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>Add Fees By Charge Type</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: "98%", m: "0 auto" }}>
                        <Paper sx={{ backgroundColor: "inherit", color: "inherit", mb: '1vh' }} elevation={5}>
                            <Box sx={{ p: '.5vh' }}>
                                <strong>
                                    <h4>
                                        <strong>
                                            Selected Violation:
                                        </strong>
                                    </h4>
                                    <p>Charge Type: {violationView.chargeType}</p>
                                    <p>Statute: {violationData.currentStatute}</p>
                                    <p>Description: {violationView.description}</p>
                                </strong>
                            </Box>
                        </Paper>
                        <Paper elevation={5}>
                            <CaseTypeFeesTable
                                setSelectedRow={setSelectedFee}
                                feeRules={feeRules}
                                setFeeRules={setFeeRules}
                                entityId={entityId}
                                violationDate={violationDate}
                                addFees={addFees}
                            />
                        </Paper>
                        <Box sx={
                            {
                                display: "flex",
                                justifyContent: "right",
                                gap: '.5rem',
                                p: "1vh 0"
                            }
                        }
                        >
                            <Button
                                variant="contained"
                                sx={
                                    {
                                        backgroundColor: "steelblue",
                                        color: "white"
                                    }
                                }
                                onClick={addFees}
                            >
                                Add Fees
                            </Button>
                            <Button
                                variant="contained"
                                sx={
                                    {
                                        backgroundColor: "steelblue",
                                        color: "white"
                                    }
                                }
                                onClick={
                                    () => {
                                        initVioFeeCharge ? setModalType('violationInfo') : handleClose()
                                    }
                                }
                            >
                                {initVioFeeCharge ? 'Back to Violation' : 'Close'}
                            </Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'caseLevelFees' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                        <h1>
                            <strong>Add Case Level Fees</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: "98%", m: "0 auto", maxHeight: '50%' }}>
                        <Paper sx={{ height: "50vh", overflow: "hidden" }} elevation={5}>
                            <AddChargeViewTable
                                statuteRows={statuteRows}
                                getRow={getRow}
                                loading={loading}
                                addViolation={addViolation}
                                modalType={modalType}
                                amendViolation={amendToViolation}
                                caseLevelFees={true}
                            />
                        </Paper>
                        <Box sx={{ display: "flex", justifyContent: "right", gap: '.5rem', p: "1vh 0" }}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "steelblue", color: "white" }}
                                disabled={statuteId === null}
                                onClick={() => {
                                    setCaseLevelFees(true);
                                    addViolation()
                                }}>Add Fees</Button>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "steelblue", color: "white" }}
                                onClick={() => {
                                    setStatuteId(null);
                                    handleClose();
                                    setCaseLevelFees(false);
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'deleteFees' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                        <h1>
                            <strong>Delete Violation Cost & Fees</strong>
                        </h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <h3 style={{ textAlign: 'center' }}
                        >
                            You are about to Delete all of the Cost and fees from:
                            <br />
                            {violationView.description}
                            <br />
                            Please click <code style={{ color: 'limegreen' }}>Delete Fees</code> to continue, or <code style={{ color: 'orangered' }}>Cancel</code> to go back!
                        </h3>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={(e) => deleteAllFees(e, violationView.pkViolationId)}>Delete Fees</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'suspendAll' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                        <h3>
                            <strong>Suspend All Violation Cost & Fees</strong>
                        </h3>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <h3 style={{ textAlign: 'center' }}
                        >
                            You are about to Suspend all of the Cost and fees from:
                            <br />
                            {violationView.description}
                            <br />
                            Please click <code style={{ color: 'limegreen' }}>Suspend Fees</code> to continue, or <code style={{ color: 'orangered' }}>Cancel</code> to go back!
                        </h3>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={suspendFeeFines}>Suspend Fees</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'revertSuspended' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                        <h3>
                            <strong>Revert All Suspended Violation Cost & Fees</strong>
                        </h3>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <h3 style={{ textAlign: 'center' }}
                        >
                            You are about to Revert all of the suspended cost and fees from:
                            <br />
                            {violationView.description}
                            <br />
                            Please click <code style={{ color: 'limegreen' }}>Revert Fees</code> to continue, or <code style={{ color: 'orangered' }}>Cancel</code> to go back!
                        </h3>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={revertFeeFineSuspendedCost}>Revert Fees</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'revertCleared' && (
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                        <h3>
                            <strong>Revert All Cleared Violation Cost & Fees</strong>
                        </h3>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <h3 style={{ textAlign: 'center' }}
                        >
                            You are about to Revert all of the cleared cost and fees from:
                            <br />
                            {violationView.description}
                            <br />
                            Please click <code style={{ color: 'limegreen' }}>Revert Fees</code> to continue, or <code style={{ color: 'orangered' }}>Cancel</code> to go back!
                        </h3>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={revertCleared}>Revert Fees</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <LinearProgress />
                    }
                </Paper>
            )
        }
        {
            modalType === 'Comments' && (
                <>
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center', height: 'auto', display: 'flex' }}>
                            <h4 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>
                                <strong>Violation Comments</strong>
                            </h4>
                        </Paper>
                        <Box sx={{ p: '1vh' }}>
                            <TextField
                                sx={ts}
                                multiline
                                rows={11}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                fullWidth
                                label="Comments"
                                variant="outlined"
                                InputLabelProps={{shrink: true}}
                                InputProps={{ readOnly: true }}
                            />
                            {addingComments &&
                                <TextField
                                sx={{ ...ts, mt: '2vh' }}
                                multiline
                                rows={4}
                                value={additionalComments}
                                onChange={(e) => setAdditionalComments(e.target.value)}
                                fullWidth
                                label="Additional Comments"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{`<${userName} ${new Date().toDateString()}>`}</InputAdornment>
                                }}
                                />
                            }
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right', p: '1vh' }}>
                            {!addingComments &&
                                <Button onClick={() => setAddingComments(true)} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>
                                    Add Comments
                                </Button>
                            }
                            {addingComments &&
                                <Button onClick={saveVioComments} disabled={additionalComments.length < 1} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>
                                    Save Comments
                                </Button>
                            }
                            <Button onClick={() => { handleClose(); setComments('') }} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Close</Button>
                        </Box>
                    </Paper>
                    {
                        loading && <LinearProgress />
                    }
                </>
            )
        }
    </>);
}

export default ViolationModals;