import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { TelerikReportViewer } from '@progress/telerik-react-report-viewer'
import { Button, Box, MenuItem, Paper, TextField, Grid } from '@mui/material';

import { ThemeModeContext, apiRoute } from '../../App';
import { UserContext } from '../../context/UserContext';
import { formattedDate, formatTime } from '../../Utils';

import ReportPicker from './ReportPicker';

const ReportViewer = (props) => {

    const [reportsCategory, setReportsCategory] = useState("collection")
    const [reportName, setReportName] = useState("LandingReport")
    const currentDate = new Date();
    const formattedDefaultDate = currentDate.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(formattedDefaultDate);
    const [endDate, setEndDate] = useState(formattedDefaultDate);
    const [disburseDate, setDisburseDate] = useState();
    const [disburseId, setDisburseId] = useState();
    const [loadingDates, setLoadingDates] = useState(false);
    const [disburseDatesList, setDisburseDatesList] = useState([]);
    const [previousDate, setPreviousDate] = useState();

    let viewer;
    const { mode } = useContext(ThemeModeContext);
    const { entityId } = useContext(UserContext);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    }


    const reportOptions = [
        { key: 'PaidCaseReport', name: 'PaidCaseReport', category: "collection" },
        { key: 'DistributionReport', name: 'DistributionReport', category: 'disbursement' }
    ]

    const switchReports = async () => {
        console.log('Switch Reports START');
        const reportCategory = reportOptions.find(report => report.name === reportName)?.category

        if (reportCategory === 'collection') {

            const endDateParam = new Date(endDate);
            endDateParam.setHours(23, 59, 59, 999)
            const params = {
                startDate: new Date(startDate),
                endDate: endDateParam,
                EntityId: parseInt(entityId)
            }
            viewer?.setReportSource({ report: `${reportName}.trdp`, parameters: params });
        } else if (reportCategory === 'disbursement') {

            const params = { disburseId: disburseId, disburseDate: new Date(disburseDate), prevDate: new Date(previousDate), EntityId: parseInt(entityId) }
            viewer?.setReportSource({ report: `${reportName}.trdp`, parameters: params });
        }
    }

    const getDisburseDates = () => {
        setLoadingDates(true);
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formattedDate(row.disburseDate),
                        Time: formatTime(row.disburseDate),
                        FullDate: row.disburseDate,
                        EnteredBy: row.enteredBy,
                        Value: row.masterDisburseId
                    }
                })
                setDisburseDatesList(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
            })
            .finally(() => setLoadingDates(false))
    };

    useEffect(() => {
        getDisburseDates();
    }, [])

    return (
        <Box sx={{ display: 'flex', height: '90vh', gap: 2, mt: '.5vh' }} >
            {/* Report Picker and configuration section */}
            <Paper
                elevation={10}
                sx={{
                    flex: '0 0 35%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', padding: .6 }} >
                    <h5 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px', marginLeft: '4px', color: 'white' }}>Report Options</h5>
                </Paper>
                <ReportPicker
                    switchReports={switchReports}
                    reportsCategory={reportsCategory}
                    setReportsCategory={setReportsCategory}
                    textFieldStyle={textFieldStyle}
                    reportOptions={reportOptions}
                    setReportName={setReportName}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    reportName={reportName}
                    startDate={startDate}
                    endDate={endDate}
                    disburseDatesList={disburseDatesList}
                    loadingDates={loadingDates}
                    setDisburseDate={setDisburseDate}
                    setPreviousDate={setPreviousDate}
                    setDisburseId={setDisburseId}
                    disburseId={disburseId}
                />
            </Paper>

            {/* Report Viewer section */}
            <Paper
                elevation={10}
                sx={{
                    flex: '1', // Remaining 65% width
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                }}
            >
                <TelerikReportViewer
                    ref={el => viewer = el}
                    serviceUrl={`${apiRoute}/api/reports`}
                    reportSource={{ report: `${reportName}.trdp`, parameters: {} }}
                    error={(e, args) => {
                        try {
                            console.log('report failed event', e);
                            console.log('report failed message', args);

                            e?.target?.commands?.stopRendering?.exec();
                            e?.target?.reportSource({ report: `SampleReport.trdp`, parameters: {} });
                            e?.target?.refreshReport();
                        } catch (error) {
                            console.error("Error inside viewer error handling:", error);
                        }
                    }}
                    viewerContainerStyle={{
                        position: 'absolute',
                        left: '36.5vw',
                        right: '1.0vw',
                        top: '8.5vh',
                        bottom: '3.4vh',
                        overflow: 'auto',
                        clear: 'both',
                        fontFamily: 'ms sans serif',
                        textTransform: 'none'
                    }}
                    viewMode="INTERACTIVE"
                    scaleMode="SPECIFIC"
                    pageMode="SINGLE_PAGE"
                    scale={1.0}
                    enableAccessibility={false}
                />
            </Paper>
        </Box>
    )
}

export default ReportViewer;